import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';
import { AppointmentApi } from '../../../../../services/api';
import { excludeRequest, scrollTop } from '../../../../Admin/assets/scripts/global';

import './style.scss';

export default function LevelsRegister() {
  //const toast = usetoast();
  const history = useNavigate();
  const isEdit = history.location.search.split('=').length > 1;
  const id = isEdit ? history.location.search.split('=')[1] : null;

  function fillFields(data) {
    document.getElementById('levelField').value = data.st_description;
  }

  function getLevel() {
    const data = {
      params: { id },
    };
    AppointmentApi.get('/KnowledgeLevels', data)
      .then((res) => {
        fillFields(res.data);
      })
      .catch((err) => err.response.data);
  }

  // Limpar campos
  function clearFields() {
    document.getElementById('levelField').value = '';
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    const level = document.getElementById('levelField');

    if (level.value === '') {
      level.classList.add('border-danger');
      isValid = false;
    } else {
      level.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.success('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Requisição insert
  function LevelInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      const data = isEdit
        ? JSON.stringify({
            cd_level: id,
            st_description: document.getElementById('levelField').value,
            cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
          })
        : JSON.stringify({
            st_description: document.getElementById('levelField').value,
            cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
          });

      // Requisição
      if (isEdit) {
        AppointmentApi.put('/KnowledgeLevels', data)
          .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success(res.data);
              clearFields();
              scrollTop();
              history.push('/Recrutamento/Niveis');
            }
          })
          .catch((err) => {
            toast.error(err.response.data);
            document.getElementById('delete').disabled = false;
            document.getElementById('save').disabled = false;
          });
      } else {
        AppointmentApi.post('/KnowledgeLevels', data)
          .then(async (res) => {
            if (res.status === 201) {
              toast.success(res.data);
              clearFields();
              if (redirect) {
                history.push('/Recrutamento/Niveis');
              } else {
                scrollTop();
                document.getElementById('saveAndNew').disabled = false;
                document.getElementById('save').disabled = false;
              }
            }
          })
          .catch((err) => {
            toast.error(err.response.data);
            document.getElementById('saveAndNew').disabled = false;
            document.getElementById('save').disabled = false;
          });
      }
    }
  }

  useEffect(() => {
    if (isEdit) {
      getLevel();
    } else {
      clearFields();
    }
  });

  return (
    <>
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header d-flex justify-content">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Recrutamento/Niveis">Níveis</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? 'Edição' : 'Registro'} de Nível</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-body">
              <div className="row">
                <label className="text-color-light" htmlFor="levelField">
                  Nível
                  <input id="levelField" type="text" className="mt-2" />
                </label>
              </div>

              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      LevelInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        LevelInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    LevelInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      LevelInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest(id, 'KnowledgeLevels');
                    history.push('/Recrutamento/Niveis');
                  }}
                >
                  Sim
                </button>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

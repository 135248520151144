import React, { useEffect } from 'react';
import Inputmask from 'inputmask';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { AppointmentApi } from '../../../../services/api';
import json from '../../assets/scripts/statesCities.json';
import { validateEmail } from '../../assets/scripts/global';

import './style.scss';

export default function CarreerForm() {
  const navigate = useNavigate();

  function UF() {
    document.getElementById('cityField').innerHTML =
      "<option value='Selecione...'>Selecione...</option>";
    const acronym = document.getElementById('stateField').value;
    json.states.forEach((v) => {
      if (v.acronym === acronym) {
        v.citys.forEach((val) => {
          const tempOption = document.createElement('option');
          tempOption.value = val;
          tempOption.innerText = val;
          document.getElementById('cityField').appendChild(tempOption);
        });
      }
    });
  }

  // Requisição de insert do candidato
  async function InsertCandidate() {
    document.getElementById('send').disabled = true;

    const formdata = new FormData();
    formdata.append('type', 'candidate');
    formdata.append('cd_application_vacancy', window.location.href.split('=')[1]);
    formdata.append('st_full_name', document.getElementById('nameField').value);
    formdata.append('st_email', document.getElementById('emailField').value);
    formdata.append('st_phone', document.getElementById('phoneField').value);
    formdata.append('js_state', document.getElementById('stateField').value);
    formdata.append('js_city', document.getElementById('cityField').value);
    formdata.append('file', document.getElementById('fileField').files[0]);
    formdata.append('fl_working', document.getElementById('availabilityField').checked ? 1 : 0);
    formdata.append('fl_address_change', document.getElementById('employedField').checked ? 1 : 0);
    if (
      document.getElementById('linkedinField').value !== undefined &&
      document.getElementById('linkedinField').value !== ''
    ) {
      formdata.append('lk_link_linkedin', document.getElementById('linkedinField').value);
    }
    if (
      document.getElementById('fileField').value !== '' &&
      document.getElementById('fileField').files[0] !== undefined
    ) {
      formdata.append('file', document.getElementById('fileField').files[0]);
      formdata.append('up_cv_path', document.getElementById('fileField').value);
    }
    formdata.append('cd_user', JSON.parse(localStorage.getItem('nbw__userData')).id);

    // Requisição
    await AppointmentApi.post('/Candidates', formdata)
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success('Muito obrigado por se candidatar 💙!');
          navigate.push('/');
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
        document.getElementById('send').disabled = false;
      });
  }

  // function para validar input file
  function validateFileExtension() {
    const file = document.getElementById('fileField');

    if (file && file !== undefined) {
      file.onchange = () => {
        if (file.value) {
          if (file.value.split('.')[1].toLowerCase() !== 'pdf') {
            toast.error('Insira um arquivo PDF!');
            file.value = '';
            document.innerText = 'Nenhum arquivo selecionado';
          } else {
            const fileName = file.value.split('\\')[2].split('.')[0];
            document.innerText = fileName;
          }
        }
      };
    }
  }

  function validateFields() {
    let isValid = true;
    let mandatoryFields = true;
    const nameField = document.getElementById('nameField');
    const emailField = document.getElementById('emailField');
    const phoneField = document.getElementById('phoneField');
    const stateField = document.getElementById('stateField');
    const cityField = document.getElementById('cityField');
    const cvFileField = document.getElementById('fileField');

    if (nameField.value === '') {
      nameField.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      nameField.classList.remove('border-danger');
    }

    if (emailField.value === '') {
      emailField.classList.add('border-danger');
      mandatoryFields = false;
    } else if (validateEmail(emailField.value)) {
      emailField.classList.remove('border-danger');
    } else {
      emailField.classList.add('border-danger');
      toast.error('E-mail inválido!');
      isValid = false;
    }

    if (phoneField.value === '') {
      phoneField.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      phoneField.classList.remove('border-danger');
    }

    if (stateField.value === 'Selecione...') {
      stateField.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      stateField.classList.remove('border-danger');
    }

    if (cityField.value === 'Selecione...') {
      cityField.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      cityField.classList.remove('border-danger');
    }

    if (cvFileField.value === '') {
      cvFileField.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      cvFileField.classList.remove('border-danger');
    }

    if (!mandatoryFields) {
      toast.error('Campos obrigatórios não preenchidos!');
      isValid = false;
    } else {
      InsertCandidate();
      isValid = true;
    }

    return isValid;
  }

  useEffect(() => {
    validateFileExtension();

    Inputmask({ mask: '(99) [9] 9999-9999' }).mask(document.getElementById('phoneField'));
  });

  return (
    <>
    <ToastContainer />
      {/* Form */}
      <div className="container carreer-form-container">
        <div className="row align-items-start">
          <h2 className="title-3">CANDIDATE-SE</h2>

          {/* Row 1 */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-md-5 mt-3">
            <label className="form-label" htmlFor="nameField">
              Nome completo*
              <input type="text" className="form-control" id="nameField" />
            </label>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-lg-5 mt-xs-2 mt-sm-5 mt-2">
            <label className="form-label" htmlFor="emailField">
              E-mail*
              <input
                type="text"
                className="form-control"
                id="emailField"
                placeholder="exemplo@email.com"
              />
            </label>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-lg-5 mt-xs-2 mt-sm-2 mt-2">
            <label className="form-label" htmlFor="linkedinField">
              Linkedin
              <input
                type="text"
                className="form-control"
                id="linkedinField"
                placeholder="https://www.linkedin.com/in/"
              />
            </label>
          </div>

          {/* Row 2 */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-2">
            <label className="form-label" htmlFor="phoneField">
              Telefone*
              <input
                type="text"
                className="form-control"
                id="phoneField"
                placeholder="(00) 9 0000-0000"
              />
            </label>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-2">
            <label className="form-label" htmlFor="stateField">
              Estado*
              <select className="form-select" id="stateField" onChange={UF}>
                <option defaultValue>Selecione...</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AM">Amazonas</option>
                <option value="AP">Amapá</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santos</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MG">Minas Gerais</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MT">Mato Grosso</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="PR">Paraná</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="SC">Santa Catarina</option>
                <option value="SE">Sergipe</option>
                <option value="SP">São Paulo</option>
                <option value="TO">Tocantins</option>
              </select>
            </label>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-2">
            <label className="form-label" htmlFor="cityField">
              Cidade*
              <select className="form-select" id="cityField">
                <option defaultValue>Selecione...</option>
              </select>
            </label>
          </div>

          {/* Row 3 */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3 mt-2">
            <label className="form-label" htmlFor="fileField">
              Anexe seu currículo*
              <input className="form-control" type="file" id="fileField" />
            </label>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3 mt-lg-5 mt-xs-3 mt-sm-3 mt-3 form-check">
            <label className="form-check-label" htmlFor="availabilityField">
              Disponibilidade para mudar de cidade
              <input className="form-check-input" type="checkbox" value="" id="availabilityField" />
            </label>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 mt-lg-5 mt-xs-3 mt-sm-3 mt-3 ms-lg-0 form-check">
            <label className="form-check-label" htmlFor="employedField">
              Atualmente Empregado
              <input className="form-check-input" type="checkbox" value="" id="employedField" />
            </label>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5 mt-2">
            <button
              id="send"
              type="button"
              className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 btn btn-primary"
              onClick={validateFields}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import { VscColorMode } from 'react-icons/vsc';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import './style.scss';

const MyEditor = () => {
  useEffect(() => {
    const editor = new Quill('#editor', {
      theme: 'snow'
    });

    editor.register(
      {
        'formats/emoji': Quill.EmojiBlot,
        'modules/emoji-toolbar': Quill.ToolbarEmoji,
        'modules/emoji-textarea': Quill.TextAreaEmoji,
        'modules/emoji-shortname': Quill.ShortNameEmoji,
      },
      true
    );
  }, []);
}

// Quill.register(
//   {
//     'formats/emoji': Quill.EmojiBlot,
//     'modules/emoji-toolbar': Quill.ToolbarEmoji,
//     'modules/emoji-textarea': Quill.TextAreaEmoji,
//     'modules/emoji-shortname': Quill.ShortNameEmoji,
//   },
//   true
// );

const toolbarOptions = [
  { font: [] },
  { size: ['huge', 'large', false, 'small'] },
  {
    color: [
      '#000000',
      '#e60000',
      '#ff9900',
      '#ffff00',
      '#008a00',
      '#0066cc',
      '#9933ff',
      '#ffffff',
      '#facccc',
      '#ffebcc',
      '#ffffcc',
      '#cce8cc',
      '#cce0f5',
      '#ebd6ff',
      '#bbbbbb',
      '#f06666',
      '#ffc266',
      '#ffff66',
      '#66b966',
      '#66a3e0',
      '#c285ff',
      '#888888',
      '#a10000',
      '#b26b00',
      '#b2b200',
      '#006100',
      '#0047b2',
      '#6b24b2',
      '#444444',
      '#5c0000',
      '#663d00',
      '#666600',
      '#003700',
      '#002966',
      '#3d1466',
      '#36b4e5',
      '#18355e',
    ],
  },
  { background: [] },
  'bold',
  'italic',
  'underline',
  'strike',
  { align: [] },
  'emoji',
  { list: 'ordered' },
  { list: 'bullet' },
  { script: 'sub' },
  { script: 'super' },
  'link',
  'image',
  'clean',
];

export default function CompDatePicker() {
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const quill = new Quill('.wysiwyg-editor', {
      modules: {
        toolbar: toolbarOptions,
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
      },
      theme: 'snow',
    });
    const customButton = document.createElement('button');
    customButton.classList.add('ql-change-background');
    customButton.innerHTML = document.getElementById('customButton').innerHTML;
    customButton.onclick = () => {
      document.getElementsByClassName('wysiwyg-editor')[0].classList.toggle('wysiwyg-light');
    };
    document.getElementsByClassName('ql-formats')[0].appendChild(customButton);
  }, []);
  return (
    <>
      <div className="wysiwyg-container mt-2">
        <div className="wysiwyg-editor wysiwyg-light" />
      </div>
      <div id="customButton" className="d-none">
        <VscColorMode />
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { Select, Breadcrumb, DatePicker, Input } from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/en_US';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { AppointmentApi } from '../../../../../services/api';
import { scrollTop, validateEmail, excludeRequest } from '../../../assets/scripts/global';

import './style.scss';

export default function UsersRegister() {
  const history = useNavigate();
  const isEdit = history.location.search.split('=').length > 1;
  const id = isEdit ? history.location.search.split('=')[1] : null;

  // const [stateDatePicker, setStateDateOptions] = useState();
  const [stateProfileOptions, setStateProfileOptions] = useState([]);

  // States com os valores dos campos
  const [valueProfile, setValueProfile] = useState({ value: null });
  const [valuePassword, setValuePassword] = useState();
  const [valueConfirmPassword, setValueConfirmPassword] = useState();
  const [valueBirthday, setValueBirthday] = useState(moment());
  let optionsFilled = false;

  // Get Profiles
  async function fillProfileOptions() {
    await AppointmentApi.get('/Profiles/GetSelectOptions')
      .then((response) => {
        setStateProfileOptions(response.data);
      })
      .catch((err) => err);
  }

  // Limpa o optionsFilled para realizar preenchimento dos campos novamente
  history.listen((location) => {
    if (location.pathname + location.search.split('=')[0] !== '/Admin/Usuarios/Registro?cd_user') {
      optionsFilled = false;
    }
  });

  // Preenche os campos em editar
  function fillFields(data) {
    document.getElementById('nameField').value = data.st_name;
    document.getElementById('usernameField').value = data.st_login;
    document.getElementById('emailField').value = data.st_email;
    setValueProfile(data.cd_profile);
    setValuePassword(data.st_password);
    setValueConfirmPassword(data.st_password);
    document.getElementById('cpfField').value = data.st_cpf;
    document.getElementsByName('birthday')[0].value = data.dt_birthday;
    if (data.dt_birthday !== undefined) {
      setValueBirthday(moment(data.dt_birthday.split('T')[0]));
    }
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    let mandatoryFields = true;
    const name = document.getElementById('nameField');
    const username = document.getElementById('usernameField');
    const email = document.getElementById('emailField');
    const cpf = document.getElementById('cpfField');
    const birthday = document.getElementsByName('birthday')[0].offsetParent.offsetParent;
    const password = document.getElementById('passwordField');
    const confirmPassword = document.getElementById('confirmPasswordField');
    const profile = document.getElementsByName('profile')[0].firstChild;

    if (name.value === '') {
      name.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      name.classList.remove('border-danger');
    }

    if (username.value === '') {
      username.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      username.classList.remove('border-danger');
    }

    if (document.getElementById('emailField').value === '') {
      email.classList.add('border-danger');
      mandatoryFields = false;
    } else if (validateEmail(email.value)) {
      email.classList.remove('border-danger');
    } else {
      email.classList.add('border-danger');
      toast.error('E-mail inválido!');
      isValid = false;
    }

    if (cpf.value === '' || cpf.value.length < 14) {
      cpf.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      cpf.classList.remove('border-danger');
    }

    if (!valueBirthday) {
      birthday.classList.add('border-danger');
      isValid = false;
    } else {
      birthday.classList.remove('border-danger');
    }

    if (!isEdit) {
      if (password.value === '' || confirmPassword.value !== password.value) {
        document.getElementById('passwordField').offsetParent.style.border = '1px #cf4141 solid';
        mandatoryFields = false;
      } else {
        document.getElementById('passwordField').offsetParent.style.border = '1px #48494c solid';
      }

      if (confirmPassword.value === '' || confirmPassword.value !== password.value) {
        document.getElementById('confirmPasswordField').offsetParent.style.border =
          '1px #cf4141 solid';
        mandatoryFields = false;
      } else {
        document.getElementById('confirmPasswordField').offsetParent.style.border =
          '1px #48494c solid';
      }
    }

    if (valueProfile === undefined || !valueProfile || valueProfile === null) {
      profile.classList.add('border-danger');
      isValid = false;
    } else {
      profile.classList.remove('border-danger');
    }

    if (!mandatoryFields || !isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
      isValid = false;
    } else {
      isValid = true;
    }
    if (confirmPassword.value !== password.value) {
      toast.error('Senhas não correspondem!');
      isValid = false;
    }

    return isValid;
  }

  // Limpa os campos
  function clearFields() {
    document.getElementById('nameField').value = '';
    document.getElementById('usernameField').value = '';
    document.getElementById('emailField').value = '';
    // document.getElementById('passwordField').value = '';
    // document.getElementById('confirmPasswordField').value = '';
    document.getElementById('cpfField').value = '';
    setValuePassword(undefined);
    setValueConfirmPassword(undefined);
    setValueProfile(undefined);
    setValueBirthday(undefined);
  }

  async function getDataUsers() {
    AppointmentApi.get('Users', {
      params: {
        id,
      },
    }).then((res) => {
      fillFields(res.data);
    });
  }

  // Request para inserir
  function UserInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      const data = {
        st_name: document.getElementById('nameField').value,
        st_login: document.getElementById('usernameField').value,
        st_email: document.getElementById('emailField').value,
        st_cpf: document.getElementById('cpfField').value,
        dt_birthday: valueBirthday,
        st_password: valueConfirmPassword,
        cd_profile: valueProfile.value,
        st_profile: valueProfile.label,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      };

      if (isEdit) {
        Object.assign(data, { cd_usr: id });

        if (data.st_password === '' || data.st_password === undefined) {
          delete data.st_password;
        }
      }

      // Requisição
      AppointmentApi({
        method: isEdit ? 'PUT' : 'POST',
        url: '/Users',
        data,
      })
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data);
            document.getElementById('save').disabled = false;
            if (redirect) {
              history.push('/Admin/Usuarios');
            } else {
              scrollTop();
              clearFields();
              if (isEdit) {
                document.getElementById('delete').disabled = false;
              } else {
                document.getElementById('saveAndNew').disabled = false;
              }
              document.getElementById('save').disabled = false;
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data);

          if (isEdit) {
            document.getElementById('delete').disabled = false;
          } else {
            document.getElementById('saveAndNew').disabled = false;
          }
          document.getElementById('save').disabled = false;
        });
    }
  }

  useEffect(async () => {
    if (!isEdit) {
      if (stateProfileOptions !== undefined) {
        clearFields();
      }
    }
    // eslint-disable-next-line no-undef
    Inputmask({ mask: '999.999.999-99' }).mask(document.getElementById('cpfField'));
  }, []);

  useEffect(async () => {
    if (!optionsFilled) {
      await fillProfileOptions();
      optionsFilled = true;
    }
  }, [optionsFilled]);

  useEffect(async () => {
    if (isEdit) {
      getDataUsers();
    }
  }, []);

  return (
    <>
    <ToastContainer />
      <div className="page-content user-register">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-12">
          <div className="card">
            <div className="card-header d-flex justify-content">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Admin/Usuarios">Usuários</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? 'Edição' : 'Registro'} de Usuário</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  Nome completo
                  <input id="nameField" type="text" />
                </label>
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="usernameField"
                >
                  Login
                  <input id="usernameField" type="text" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="emailField"
                >
                  E-mail
                  <input id="emailField" type="text" />
                </label>
              </div>

              {/* Row 2 */}
              <div className="row mt-4">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="cpfField"
                >
                  CPF
                  <input id="cpfField" type="text" />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  Data de Nascimento
                  <DatePicker
                    name="birthday"
                    placeholder="Selecionar Data"
                    value={valueBirthday}
                    onChange={(e) => {
                      if (e) {
                        setValueBirthday(e);
                      } else {
                        setValueBirthday(undefined);
                      }
                    }}
                    format="DD/MM/YYYY"
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        today: 'Hoje',
                      },
                    }}
                  />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  Perfil
                  {stateProfileOptions && (
                    <Select
                      name="profile"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueProfile}
                      optionFilterProp="label"
                      options={stateProfileOptions}
                      onChange={(e, value) => {
                        if (e !== undefined) {
                          setValueProfile(value);
                        } else {
                          setValueProfile(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateProfileOptions && <Select loading />}
                </label>
              </div>

              {/* Row 3 */}
              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="passwordField"
                >
                  Senha
                  <Input.Password
                    id="passwordField"
                    type="password"
                    value={valuePassword}
                    placeholder="Digite uma senha"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e) => {
                      if (e) {
                        setValuePassword(e.target.value);
                      } else {
                        setValuePassword(undefined);
                      }
                    }}
                  />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="confirmPasswordField"
                >
                  Confirmação Senha
                  <Input.Password
                    id="confirmPasswordField"
                    type="password"
                    value={valueConfirmPassword}
                    placeholder="Confirme a senha"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e) => {
                      if (e) {
                        setValueConfirmPassword(e.target.value);
                      } else {
                        setValueConfirmPassword(undefined);
                      }
                    }}
                  />
                </label>
              </div>

              {/* Row 4 */}
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      UserInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        UserInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    UserInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      UserInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest(id, 'Users');
                    history.push('/Admin/Usuarios');
                  }}
                >
                  Excluir
                </button>
                <button type="button" className="btn btn-default-outline" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import {
  FaCity,
  FaLaptopCode,
  FaCode,
  FaProjectDiagram,
  FaUserCog,
  FaNetworkWired,
  FaArrowLeft,
  FaArrowRight,
} from 'react-icons/fa';
import Carousel from 'react-multi-carousel';

import './style.scss';

import logoMicrosoftPartner from '../../assets/img/logoMicrosoftPartner.png';
import logoItil from '../../assets/img/logoItil.png';
import logoAngular from '../../assets/img/ServicesLogos/logoAngular.png';
import logoJava from '../../assets/img/ServicesLogos/logoJava.png';
import logoLinx from '../../assets/img/ServicesLogos/logoLinx.png';
import logoMysql from '../../assets/img/ServicesLogos/logoMysql.png';
import logoNode from '../../assets/img/ServicesLogos/logoNode.png';
import logoOracle from '../../assets/img/ServicesLogos/logoOracle.png';
import logoPhp from '../../assets/img/ServicesLogos/logoPhp.png';
import logoPostgres from '../../assets/img/ServicesLogos/logoPostgres.png';
import logoPython from '../../assets/img/ServicesLogos/logoPython.png';
import logoSap from '../../assets/img/ServicesLogos/logoSap.png';
import logoCsharp from '../../assets/img/ServicesLogos/logoCsharp.png';
import logoReact from '../../assets/img/ServicesLogos/logoReact.png';
import logoReactNative from '../../assets/img/ServicesLogos/logoReactNative.png';

export default function Services() {
  let numTabsAtOnce = 0;
  const windowWidth = window.innerWidth;
  let cards = [];

  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 850 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile2: {
      breakpoint: { max: 849, min: 501 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  function CustomRightArrow() {
    return false;
  }
  function CustomLeftArrow() {
    return false;
  }

  function setCardMenu(card) {
    const currentCard = card;
    if (currentCard.getElementsByClassName('card-header-tabs').length > 0) {
      const cardTabs = card
        .getElementsByClassName('card-header-tabs')[0]
        .getElementsByClassName('nav-item');
      const tabsDisplayed = [];
      const cardBodies = currentCard.getElementsByClassName('card-body');
      for (let i = 0; i < cardTabs.length; i += 1) {
        if (i <= numTabsAtOnce - 1) tabsDisplayed.push(1);
        else tabsDisplayed.push(0);

        const button = cardTabs[i].getElementsByTagName('button')[0];
        button.onclick = () => {
          for (let j = 0; j < cardTabs.length; j += 1) {
            cardTabs[j].getElementsByTagName('button')[0].classList.remove('active');
          }
          button.classList.add('active');
          for (let k = 0; k < cardBodies.length; k += 1) {
            cardBodies[k].classList.add('d-none');
          }
          currentCard
            .getElementsByClassName(`card-body-${button.id}`)[0]
            .classList.remove('d-none');
        };
      }

      for (let l = 0; l < tabsDisplayed.length; l += 1) {
        if (tabsDisplayed[l] === 1) cardTabs[l].classList.remove('d-none');
        else cardTabs[l].classList.add('d-none');
      }
      if (
        currentCard.getElementsByClassName('card-header')[0].getElementsByClassName('menu-prev')
          .length !== 0
      ) {
        currentCard
          .getElementsByClassName('card-header')[0]
          .getElementsByClassName('menu-prev')[0].firstElementChild.onclick = () => {
          const tempChild =
            currentCard.getElementsByClassName('card-header-tabs')[0].lastElementChild;
          currentCard.getElementsByClassName('card-header-tabs')[0].lastElementChild.remove();
          currentCard.getElementsByClassName('card-header-tabs')[0].prepend(tempChild);
          for (let i = 0; i < tabsDisplayed.length; i += 1) {
            if (tabsDisplayed[i] === 1) cardTabs[i].classList.remove('d-none');
            else cardTabs[i].classList.add('d-none');
          }
          let tempId = 0;
          for (let j = 0; j < cardTabs.length; j += 1) {
            if (cardTabs[j].getElementsByTagName('button')[0].classList.contains('active')) {
              cardTabs[j].getElementsByTagName('button')[0].classList.remove('active');
              if (j - 1 < 0) {
                tempId = cardTabs[cardTabs.length - 1].getElementsByTagName('button')[0].id;
                cardTabs[cardTabs.length - 1]
                  .getElementsByTagName('button')[0]
                  .classList.add('active');
              } else {
                tempId = cardTabs[j - 1].getElementsByTagName('button')[0].id;
                cardTabs[j - 1].getElementsByTagName('button')[0].classList.add('active');
              }
              break;
            }
          }
          for (let k = 0; k < cardBodies.length; k += 1) {
            cardBodies[k].classList.add('d-none');
          }
          currentCard.getElementsByClassName(`card-body-${tempId}`)[0].classList.remove('d-none');
        };
      }
      if (
        currentCard.getElementsByClassName('card-header')[0].getElementsByClassName('menu-next')
          .length !== 0
      ) {
        currentCard
          .getElementsByClassName('card-header')[0]
          .getElementsByClassName('menu-next')[0].firstElementChild.onclick = () => {
          const tempChild =
            currentCard.getElementsByClassName('card-header-tabs')[0].firstElementChild;
          currentCard.getElementsByClassName('card-header-tabs')[0].firstElementChild.remove();
          currentCard.getElementsByClassName('card-header-tabs')[0].append(tempChild);
          for (let i = 0; i < tabsDisplayed.length; i += 1) {
            if (tabsDisplayed[i] === 1) cardTabs[i].classList.remove('d-none');
            else cardTabs[i].classList.add('d-none');
          }
          let tempId = 0;
          for (let j = 0; j < cardTabs.length; j += 1) {
            if (cardTabs[j].getElementsByTagName('button')[0].classList.contains('active')) {
              cardTabs[j].getElementsByTagName('button')[0].classList.remove('active');
              if (j + 1 > cardTabs.length - 1) {
                tempId = cardTabs[0].getElementsByTagName('button')[0].id;
                cardTabs[0].getElementsByTagName('button')[0].classList.add('active');
              } else {
                tempId = cardTabs[j + 1].getElementsByTagName('button')[0].id;
                cardTabs[j + 1].getElementsByTagName('button')[0].classList.add('active');
              }
              break;
            }
          }
          for (let k = 0; k < cardBodies.length; k += 1) {
            cardBodies[k].classList.add('d-none');
          }
          currentCard.getElementsByClassName(`card-body-${tempId}`)[0].classList.remove('d-none');
        };
      }
    }
  }

  function numDisplayedCards() {
    if (windowWidth > 1200) {
      numTabsAtOnce = 6;
    } else if (windowWidth > 1050) {
      numTabsAtOnce = 5;
    } else if (windowWidth > 960) {
      numTabsAtOnce = 4;
    } else if (windowWidth > 767) {
      numTabsAtOnce = 3;
    } else if (windowWidth > 540) {
      numTabsAtOnce = 2;
    } else {
      numTabsAtOnce = 1;
    }

    setCardMenu(cards[0]);
  }

  cards = document.getElementsByClassName('card');
  useEffect(() => {
    numDisplayedCards();
  });

  window.onresize = () => {
    numDisplayedCards();
  };

  return (
    <>
      <div className="container container-title fadeInDown">
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <h2 className="title-3 mb-0">SOLUÇÕES PARA MELHORAR O SEU NEGÓCIO</h2>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-10">
            <div>
              <p className="mb-0 text-justify">
                Usar a tecnologia para potencializar negócios. Focar no que realmente importa para
                nossos clientes. Buscar sempre agilidade em nossa performance como hub de serviços.
                É isto que podemos colocar a disposição para o seu <strong>negócio</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container card-container">
        <div className="card rounded fadeIn">
          <div className="card-header p-0 h-60px border-bottom-0 d-flex align-items-center justify-content-between">
            <div className="mw-50px ms-3 menu-prev">
              <FaArrowLeft size="25" color="#212529" />
            </div>
            <ul className="nav nav-tabs card-header-tabs menu-autoplay justify-content-center m-0 text-center">
              <li className="nav-item mw-175px">
                <button type="button" id="5" className="nav-link rounded-top h-60px active">
                  <div className="d-flex align-items-center h-100">
                    <FaUserCog size="35" className="nbw-blue" />
                    <span className="ms-2">Gestão de Clientes – CRM</span>
                  </div>
                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="3" className="nav-link rounded-top h-60px">
                  <div className="d-flex align-items-center h-100">
                    <FaCode size="35" className="nbw-blue" />
                    <span className="ms-2">Gestão Empresarial – ERP</span>
                  </div>
                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="1" className="nav-link rounded-top h-60px">
                  <div className="d-flex align-items-center h-100">
                    <FaCity size="35" className="nbw-blue" /> <span>Gestão de Pessoas – HCM
</span>
                  </div>
                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="4" className="nav-link rounded-top h-60px">
                  <div className="d-flex align-items-center h-100">
                    <FaProjectDiagram size="35" className="nbw-blue" />
                    <span>- Gestão Logística – WMS / YMS</span>
                  </div>
                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="6" className="nav-link rounded-top h-60px">
                  <div className="d-flex align-items-center h-100">
                    <FaNetworkWired size="35" className="nbw-blue" />
                    <span className="ms-2">Acesso e Segurança</span>
                  </div>
                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="2" className="nav-link rounded-top h-60px">
                  <div className="d-flex align-items-center h-100">
                    <FaLaptopCode size="35" className="nbw-blue" />
                    <span className="ms-2">X Platform</span>
                  </div>
                </button>
              </li><li className="nav-item mw-175px">
                <button type="button" id="7" className="nav-link rounded-top h-60px">
                  <div className="d-flex align-items-center h-100">
                    <FaLaptopCode size="35" className="nbw-blue" />
                    <span className="ms-2">Wiipo</span>
                  </div>
                </button>
              </li>
            </ul>
            <div className="mw-50px me-3 menu-next">
              <FaArrowRight size="25" color="#212529" />
            </div>
          </div>
          <div className="card-body card-body-1 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">- Gestão de Pessoas – HCM
</h2>
            <p className="p-0 p-md-3 p-lg-5">
            A solução HCM (Human Capital Management) da Senior é uma plataforma completa para gestão de recursos humanos, abrangendo desde o recrutamento e seleção até o desenvolvimento e retenção de talentos, garantindo produtividade, compliance e visibilidade.

            </p>
          </div>
          <div className="card-body card-body-2 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">
             - X Platform
            </h2>
            <p className="p-0 p-md-3 p-lg-5">
            A X Platform é uma plataforma que permite personalizar e integrar diversas funcionalidades às soluções de gestão da Senior. Ela inclui um marketplace chamado Senior X Store, onde é possível acessar várias ferramentas adicionais para complementar a gestão empresarial.
            </p>
          </div>
          <div className="card-body card-body-3 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">- Gestão Empresarial – ERP
</h2>
            <p className="p-0 p-md-3 p-lg-5">
            O sistema ERP da Senior integra todas as operações empresariais, como gestão de estoques, compras, vendas, produção, planejamento de recursos e controle de qualidade, visando maior eficiência operacional, redução de erros e aprimoramento do controle de custos. 

            </p>
          </div>
          <div className="card-body card-body-4 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">
            - Gestão Logística – WMS / YMS

            </h2>
            <p className="p-0 p-md-3 p-lg-5">
            O sistema WMS (Warehouse Management System) da Senior melhora a gestão de armazenagem, otimizando a movimentação de mercadorias dentro do armazém, planejamento de embarques, redução de custos e simplificação do controle de estoque.
A solução YMS (Yard Management System) gerencia o pátio de empresas, coordenando a movimentação de veículos, filas, controle de entrada e saída, e otimizando a logística do pátio.

              <strong> assertiva</strong>.
            </p>
          </div>
          <div className="card-body card-body-5">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">- Gestão de Clientes – CRM
</h2>
            <p className="p-0 p-md-3 p-lg-5">
            Software empresarial impulsionado por IA: Substitua uma infinidade de serviços e aplicativos por uma única solução com tecnologia de IA.
Colaboração: Escritório virtual para a sua empresa trabalhar, comunicar e colaborar - em casa ou no escritório.
Chamadas de vídeo e conferências em HD: Crie conferências para até 100 participantes, converse sem limite de tempo, grave suas chamadas e mude o plano fundo.
Bate-papos: Bate-papos privados e em grupo - uma maneira rápida e fácil de discutir ideias e compartilhar arquivos.
Rastreamento do tempo de trabalho: Sua equipe pode iniciar/pausar/terminar o dia de trabalho usando um relógio on-line integrado ao Bitrix24.
CRM: Repleto de recursos de gerenciamento de clientes, suporte ao cliente e automação de marketing, o Bitrix24 CRM é um pacote de vendas digital.
Aquisição de leads: Gere leads por meio de vários canais: formulários da web, bate-papos em tempo real, chamadas telefônicas ou mídias sociais e adicione-os ao seu CRM automaticamente.
Gerenciamento de leads: Use nossa ferramenta integrada de pontuação de leads para ver em quais clientes em potencial seus representantes de vendas precisam se concentrar e atribuí-los automaticamente.
Automação de vendas: Dezenas de regras e gatilhos de automação de vendas e marketing para economizar tempo e dinheiro.

            </p>
          </div>
          <div className="card-body card-body-6 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">- Acesso e Segurança</h2>
            <p className="p-0 p-md-3 p-lg-5">
            A Ronda Senior oferece ferramentas para a gestão de acesso e segurança, permitindo o controle de entrada e saída de pessoas e veículos, monitoramento de áreas restritas e a garantia da segurança patrimonial.

            </p>
          </div>
          <div className="card-body card-body-7 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">- Wiipo</h2>
            <p className="p-0 p-md-3 p-lg-5">
            A Wiipo oferece diversas soluções financeiras, incluindo benefícios flexíveis, consignado, clube de benefícios e holerite digital, visando facilitar a gestão financeira pessoal e empresarial.

            </p>
          </div>
        </div>
      </div>

      <div className="container container-technologies pt-4 pb-3 mb-3">
        <div className="row align-items-center text-md-left">
          <div className="col-lg-7 mb-5 mb-lg-0 fadeInLeft text-center">
            <h2 className="ls-0 title-3 mb-4 text-start">TECNOLOGIAS</h2>
            <p className="text-justify">
              Assim como nas antigas maravilhas do mundo, construtores e equipes de profissionais
              utilizaram das mais diversas ferramentas para transformar material bruto em
              monumentos. Nossos profissionais e especialistas utilizam das melhores ferramentas do
              mercado para criar soluções com o máximo de qualidade e atingir o sucesso na
              transformação digital.
            </p>
            <p className="mb-3 pr-md-3 text-justify">
              Estamos a disposição para auxiliar pessoas e organizações a utilizarem também das
              melhores <strong>metodologias</strong> do mercado para aplicação tecnológica adequada.
            </p>
          </div>
          <div className="col-lg-5 text-center fadeIn">
            <div className="row tech-logos">
              <div className="col-6 col-md-6 px-0 mb-6 mb-md-0">
                <img height="60px" src={logoMicrosoftPartner} alt="MICROSOFT PARTNER SILVER" />
              </div>
              <div className="col-6 col-md-6 px-0 mb-6 mb-md-0">
                <img height="60px" src={logoItil} alt="ITIL FOUNDATION CERTIFIED" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container carousel-technologies fadeIn">
        <div className="text-center">
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1}
            keyBoardControl
            customTransition="all 6s linear"
            transitionDuration={6000}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img height="80px" src={logoAngular} alt="Logo Angular" />
            <img height="80px" src={logoJava} alt="Logo Java" />
            <img height="80px" src={logoLinx} alt="Logo Linx" />
            <img height="80px" src={logoMysql} alt="Logo MYSQL" />
            <img height="80px" src={logoNode} alt="Logo Node" />
            <img height="80px" src={logoOracle} alt="Logo Oracle" />
            <img height="80px" src={logoPhp} alt="Logo PHP" />
            <img height="80px" src={logoPostgres} alt="Logo PostgreSQL" />
            <img height="80px" src={logoPython} alt="Logo Python" />
            <img height="80px" src={logoSap} alt="Logo SAP" />
            <img height="80px" src={logoCsharp} alt="Logo C#" />
            <img height="80px" src={logoReact} alt="Logo React" />
            <img height="80px" src={logoReactNative} alt="Logo React Native" />
          </Carousel>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import Async from 'react-async';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppointmentApi } from '../../../../services/api';
import { scrollTop } from '../../assets/scripts/global';

import './style.scss';

import loader from '../../../../img/loader.svg';

// const loadVacancy = async () => {
//   try {
//     const id = new URLSearchParams(window.location.search).get('id');
//     if (!id) {
//       throw new Error('O id nao foi encontrado nesta URLn');
//     }

//     const response = await AppointmentApi.get('/Vacancies/Details', {
//       params: { id },
//     });

//     return response.data;
//   } catch (error){
//       if (error.response) {
//         return error.response.data;
//       } else {
//         console.error(error.message);
//         return { error: error.message };
//       }
//   }
// };

//Busca vaga no endpoint
const loadVacancy = () =>
  AppointmentApi.get('/Vacancies/Details', {
    params: { id: window.location.href.split('=')[1] },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);

export default function CarreerDetails() {

  return (
    <>
    <ToastContainer />
      {/* Vacancy Details */}
      <Async promiseFn={loadVacancy}>
        <Async.Loading>
          <div className="container loader-container">
            <div className="row align-items-start">
              <img src={loader} height="120px" color="#36b4e5" alt="Loader" />
            </div>
          </div>
        </Async.Loading>
        <Async.Fulfilled>
          {(data) => (
            <div className="container container-vacancy">
              <div className="row align-items-start">
                {/* Título */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fadeIn">
                  <h2 className="title-3 text-color-primary text-center">OPORTUNIDADE</h2>
                  <h2 className="title-3 text-center">{data.vacancy.toUpperCase()}</h2>
                </div>

                {/* Descrição */}
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 fadeInLeft">
                  <h2 className="title-3">Sobre a vaga</h2>
                  <p
                    className="text-justify"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </div>

                {/* Pontos Quentes */}
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center fadeInUp">
                  {data.knowledgeLevel ? (
                    <>
                      <p className="custom-label text-highlight-2 mt-md-1 mt-4 mb-0">
                        NÍVEL DE CONHECIMENTO
                      </p>
                      <p>{data.knowledgeLevel.toUpperCase()}</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.requirements ? (
                    <>
                      <p className="custom-label text-highlight-2 mt-md-4 mt-3 mb-0">
                        REQUISITOS ESSENCIAIS
                      </p>
                      <p>{data.requirements.toUpperCase()}</p>
                    </>
                  ) : (
                    <></>
                  )}

                  {data.differentials ? (
                    <>
                      <p className="custom-label text-highlight-2 mt-md-4 mt-3 mb-0">
                        DIFERENCIAIS
                      </p>
                      <p>{data.differentials.toUpperCase()}</p>
                    </>
                  ) : (
                    <></>
                  )}

                  {data.state ? (
                    <>
                      <p className="custom-label text-highlight-2 mt-md-4 mt-3 mb-0">
                        LOCAL DE ATUAÇÃO
                      </p>
                      <p>{`${data.city.toUpperCase()}/${data.state.toUpperCase()}`}</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.workRegime ? (
                    <>
                      <p className="custom-label text-highlight-2 mt-md-4 mt-3 mb-0">
                        REGIME DE TRABALHO
                      </p>
                      <p>{data.workRegime.toUpperCase()}</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {data.employmentRegime ? (
                    <>
                      <p className="custom-label text-highlight-2 mt-md-4 mt-3 mb-0">
                        REGIME DE CONTRATAÇÃO
                      </p>
                      <p className="mb-4">{data.employmentRegime.toUpperCase()}</p>
                    </>
                  ) : (
                    <></>
                  )}
                  <Link
                    to={`/CarreerForm?cd_application_vacancy=${window.location.href.split('=')[1]}`}
                    className="fadeInLeft mb-5"
                    onClick={scrollTop}
                  >
                    <p className="custom-label text-highlight">
                      Candidate-se <FaArrowRight size="60" />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Async.Fulfilled>
        <Async.Rejected>{(error) => toast.error(error.message)}</Async.Rejected>
      </Async>
    </>
  );
}

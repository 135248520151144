/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Select, DatePicker, Breadcrumb, TimePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/en_US';

import './style.scss';
import { AppointmentApi } from '../../../../services/api';
import TableAntd from '../../../Admin/components/TableAntd';

moment.updateLocale('en', {
  monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
});

export default function Appointment() {
  const { RangePicker } = DatePicker;

  // Outros States
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tableData, setTableData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [appointmentSelected, setAppointmentSelected] = useState();

  // States de options
  const [stateCollaboratorOptions, setStateCollaboratorOptions] = useState([]);
  const [stateProjectOptions, setStateProjectOptions] = useState([]);
  const [stateCompanyOptions, setStateCompanyOptions] = useState([]);
  const [stateClientOptions, setStateClientOptions] = useState([]);

  // States com os valores dos campos
  const [valueCollaboratorFilter, setValueCollaboratorFilter] = useState();
  const [valueCompany, setValueCompany] = useState();
  const [valueCollaborator, setValueCollaborator] = useState();
  const [valueProject, setValueProject] = useState();
  const [valueClient, setValueClient] = useState();
  const [valueExtra, setValueExtra] = useState(0);
  const [valueDate, setValueDate] = useState(moment());
  const [valueTimePeriod, setValueTimePeriod] = useState();
  const [valueEndDate, setValueEndDate] = useState(moment().endOf('month').format('DD/MM/YYYY'));
  const [valueStartDate, setValueStartDate] = useState(
    moment().startOf('month').format('DD/MM/YYYY')
  );

  const extraOptions = [
    {
      label: 'Não',
      value: 0,
    },
    {
      label: 'Sim',
      value: 1,
    },
  ];

  // Get Empresas
  async function fillCompanyOptions() {
    await AppointmentApi.get('/JuridicalPersons/GetSelectOptions?type=1')
      .then((response) => {
        setStateCompanyOptions(response.data);
      })
      .catch((err) => err);
  }

  // Get Colaboradores
  async function fillCollaboratorOptions() {
    const { id } = JSON.parse(localStorage.getItem('nbw__userData'));
    const profile = JSON.parse(localStorage.getItem('nbw__userData')).profileCode;

    await AppointmentApi.get(`/Collaborators/GetSelectOptions?userId=${id}&userProfile=${profile}`)
      .then((response) => {
        setStateCollaboratorOptions(response.data);
        setValueCollaboratorFilter(response.data[0].value);
      })
      .catch((err) => err);
  }

  // Get Clientes
  async function fillClientOptions() {
    await AppointmentApi.get('/JuridicalPersons/GetSelectOptions?type=2')
      .then((response) => {
        setStateClientOptions(response.data);
      })
      .catch((err) => err);
  }

  // Get Projetos
  async function fillProjectOptions() {
    const { id } = JSON.parse(localStorage.getItem('nbw__userData'));
    const profile = JSON.parse(localStorage.getItem('nbw__userData')).profileCode;

    await AppointmentApi.get(`/Projects/GetSelectOptions?userId=${id}&userProfile=${profile}`)
      .then((response) => {
        setStateProjectOptions(response.data);
      })
      .catch((err) => err);
  }

  // Função para validar os campos
  function validateFields() {
    let isValid = true;
    const company = document.getElementsByName('company')[0].firstChild;
    const collaborator = document.getElementsByName('collaborator')[0].firstChild;
    const project = document.getElementsByName('project')[0].firstChild;
    const client = document.getElementsByName('client')[0].firstChild;
    const extra = document.getElementsByName('extra')[0].firstChild;
    const perc = document.getElementsByName('percentage')[0];
    const date = document.getElementsByName('date')[0].offsetParent.offsetParent;
    const time = document.getElementsByName('time')[0];
    const activityDescription = document.getElementsByName('activityDescription')[0];

    if (valueCompany === undefined || !valueCompany) {
      company.classList.add('border-danger');
      isValid = false;
    } else {
      company.classList.remove('border-danger');
    }

    if (valueCollaborator === undefined || !valueCollaborator) {
      collaborator.classList.add('border-danger');
      isValid = false;
    } else {
      collaborator.classList.remove('border-danger');
    }

    if (valueProject === undefined || !valueProject) {
      project.classList.add('border-danger');
      isValid = false;
    } else {
      project.classList.remove('border-danger');
    }

    if (valueClient === undefined || !valueClient) {
      client.classList.add('border-danger');
      isValid = false;
    } else {
      client.classList.remove('border-danger');
    }

    if (valueExtra === undefined) {
      extra.classList.add('border-danger');
      isValid = false;
    } else {
      extra.classList.remove('border-danger');
    }

    if (!valueDate) {
      date.classList.add('border-danger');
      isValid = false;
    } else {
      date.classList.remove('border-danger');
    }

    if (!valueTimePeriod) {
      time.classList.add('border-danger');
      isValid = false;
    } else {
      time.classList.remove('border-danger');
    }

    if (valueTimePeriod) {
      if (valueTimePeriod[1].format('HH:mm') < valueTimePeriod[0].format('HH:mm')) {
        time.classList.add('border-danger');
        toast.error('A Hora de Início não pode ser maior que a Hora de Fim!');
        return false;
      }
      if (valueTimePeriod[1].format('HH:mm') === valueTimePeriod[0].format('HH:mm')) {
        time.classList.add('border-danger');
        toast.error('A Hora de Início e Fim não podem ser iguais!');
        return false;
      }
      time.classList.remove('border-danger');
    }

    if (perc.value === '') {
      perc.classList.add('border-danger');
      isValid = false;
    } else {
      perc.classList.remove('border-danger');
    }

    if (activityDescription.value === '') {
      activityDescription.classList.add('border-danger');
      isValid = false;
    } else {
      activityDescription.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Função para limpar os campos
  async function clearFields() {
    document.getElementsByName('activityDescription')[0].value = '';
    document.getElementsByName('percentage')[0].value = '0';
    setValueCompany(undefined);
    setValueCollaborator(undefined);
    setValueProject(undefined);
    setValueClient(undefined);
    setValueExtra(0);
    setValueDate(undefined);
    setValueTimePeriod(undefined);
    setAppointmentSelected(undefined);
    setIsEdit(false);
  }

  // Get para trazer os registros
  const getData = async (collaborator, dateStart, dateEnd) => {
    setLoadingTable(true);
    await AppointmentApi.get(
      `/Appointments/GetGrid?collaborator=${collaborator}&dateStart=${dateStart}&dateEnd=${dateEnd}`
    )
      .then((res) => setTableData(res.data.map((item) => ({ ...item, key: item.cd_appointment }))))
      .catch((err) => {
        if (err.response === undefined) {
          toast.error('Servidor indisponível!');
          setTableData([]);
        }
        setTableData([]);
      });
    setLoadingTable(false);
  };

  // Request para inserir registros
  function appointmentInsert() {
    if (validateFields()) {
      document.getElementById('save').disabled = true;
      document.getElementById('clear').disabled = true;

      // Dados a ser enviados
      const data = {
        cd_collaborator: valueCollaborator,
        cd_project: valueProject,
        cd_juridical_person_company: valueCompany,
        cd_juridical_person_client: valueClient,
        fl_extra_hour: valueExtra ? 1 : 0,
        it_extra_hour_perc: parseInt(
          document.getElementsByName('percentage')[0].value.slice(0, -1),
          10
        ),
        fl_work_location: document.querySelectorAll('input[name="choice"]:checked')[0].value
          ? 1
          : 0,
        da_date: valueDate,
        ti_time_entry: valueTimePeriod[0].format('HH:mm'),
        ti_time_exit: valueTimePeriod[1].format('HH:mm'),
        st_activity_description: document.getElementsByName('activityDescription')[0].value,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      };

      if (isEdit) {
        Object.assign(data, { cd_appointment: parseInt(appointmentSelected, 10) });
      }

      AppointmentApi({
        method: isEdit ? 'PUT' : 'POST',
        url: '/Appointments',
        data,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data);
            clearFields();
            document.getElementById('save').disabled = false;
            document.getElementById('clear').disabled = false;
            getData(valueCollaboratorFilter, valueStartDate, valueEndDate);
          }
        })
        .catch((err) => {
          toast.error(err.response.data);
          document.getElementById('save').disabled = false;
          document.getElementById('clear').disabled = false;
        });
    }
  }

  // Função que pega as datas do range picker
  function rangePickerHandler(dates, dateStrings) {
    setValueStartDate(dateStrings[0]);
    setValueEndDate(dateStrings[1]);
    if (valueCollaboratorFilter) {
      getData(valueCollaboratorFilter, dateStrings[0], dateStrings[1]);
    }
  }

  // Preenche os campos para editar um registro
  useEffect(async () => {
    if (appointmentSelected !== undefined) {
      await AppointmentApi.get(`/Appointments?id=${appointmentSelected}`)
        .then((response) => {
          setValueCompany(response.data.cd_juridical_person_company);
          setValueCollaborator(response.data.cd_collaborator);
          setValueProject(response.data.cd_project);
          setValueClient(response.data.cd_juridical_person_client);
          setValueExtra(response.data.fl_extra_hour);
          document.getElementsByName('percentage')[0].value = response.data.it_extra_hour_perc;
          setValueDate(moment(response.data.da_date));
          setValueTimePeriod([
            moment(response.data.ti_time_entry, 'HH:mm a'),
            moment(response.data.ti_time_exit, 'HH:mm a'),
          ]);
          if (response.data.fl_work_location === 1) {
            document.getElementById('inPerson').checked = true;
          } else {
            document.getElementById('homeOffice').checked = true;
          }
          document.getElementsByName('activityDescription')[0].value =
            response.data.st_activity_description;
          setIsEdit(true);
        })
        .catch((err) => err);
    }
  }, [appointmentSelected]);

  // Traz os registros e preenche a grid
  useEffect(async () => {
    if (valueCollaboratorFilter) {
      await getData(valueCollaboratorFilter, valueStartDate, valueEndDate);
    }
  }, [valueCollaboratorFilter]);

  // Executa quando carrega a página, trazendo os dados de base
  useEffect(async () => {
    await fillCompanyOptions();
    await fillCollaboratorOptions();
    await fillProjectOptions();
    await fillClientOptions();
    // eslint-disable-next-line no-undef
    Inputmask({ mask: '9[9][9]%' }).mask(document.getElementsByName('percentage'));
  }, []);

  // Colunas do Grid
  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'da_date',
        title: 'Data',
        width: '12%',
        key: 'da_date',
        ...getColumnSearchProps('da_date', 'Data'),
        sorter: (a, b) => a.da_date.length - b.da_date.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'ti_time_entry',
        title: 'Início',
        width: '10%',
        key: 'ti_time_entry',
        ...getColumnSearchProps('ti_time_entry', 'Início'),
        sorter: (a, b) => a.ti_time_entry.length - b.ti_time_entry.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'ti_time_exit',
        title: 'Fim',
        width: '10%',
        key: 'ti_time_exit',
        ...getColumnSearchProps('ti_time_exit', 'Fim'),
        sorter: (a, b) => a.ti_time_exit.length - b.ti_time_exit.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'ti_total_hours',
        title: 'Total',
        width: '10%',
        key: 'ti_total_hours',
        ...getColumnSearchProps('ti_total_hours', 'Total'),
        sorter: (a, b) => a.ti_total_hours.length - b.ti_total_hours.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'st_project',
        title: 'Projeto',
        width: '15%',
        key: 'st_project',
        ...getColumnSearchProps('st_project', 'Projeto'),
        sorter: (a, b) => a.st_project.length - b.st_project.length,
        sortDirections: ['descend', 'ascend'],
        editable: false,
      },
      {
        dataIndex: 'st_activity_description',
        title: 'Descrição',
        key: 'st_activity_description',
        ...getColumnSearchProps('st_activity_description', 'Descrição'),
        sorter: (a, b) => a.st_activity_description.length - b.st_activity_description.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
    ];
    return columns;
  };

  return (
    <>
    <ToastContainer />
      <div className="page-content appointment-row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          {/* Apontamento de Horas */}
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Apontamento de Horas</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-body">
              <div className="row">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  Empresa*
                  {stateCompanyOptions && (
                    <Select
                      name="company"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueCompany}
                      optionFilterProp="label"
                      options={stateCompanyOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueCompany(e);
                        } else {
                          setValueCompany(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateCompanyOptions && <Select loading />}
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  Colaborador*
                  {stateCollaboratorOptions && (
                    <Select
                      name="collaborator"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueCollaborator}
                      optionFilterProp="label"
                      options={stateCollaboratorOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueCollaborator(e);
                        } else {
                          setValueCollaborator(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateCollaboratorOptions && <Select loading />}
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  Projeto*
                  {stateProjectOptions && (
                    <Select
                      name="project"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueProject}
                      optionFilterProp="label"
                      options={stateProjectOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueProject(e);
                        } else {
                          setValueProject(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateProjectOptions && <Select loading />}
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  Cliente*
                  {stateClientOptions && (
                    <Select
                      name="client"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueClient}
                      optionFilterProp="label"
                      options={stateClientOptions}
                      onChange={(e) => {
                        if (e !== undefined) {
                          setValueClient(e);
                        } else {
                          setValueClient(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateClientOptions && <Select loading />}
                </label>
              </div>

              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  Extra?
                  {stateClientOptions && (
                    <Select
                      name="extra"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueExtra}
                      optionFilterProp="label"
                      options={extraOptions}
                      defaultValue={extraOptions[0]}
                      onChange={(e) => {
                        setValueExtra(e);
                        if (e === 1) {
                          document.getElementsByName('percentage').disabled = false;
                        } else {
                          document.getElementsByName('percentage').disabled = true;
                          document.getElementsByName('percentage').value = 0;
                          setValueClient(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateClientOptions && <Select loading />}
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-1 col-xl-1">
                  Percent.
                  <input name="percentage" type="text" className="" defaultValue="0" disabled />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  Data*
                  <DatePicker
                    name="date"
                    placeholder="Selecionar Data"
                    value={valueDate}
                    defaultValue={moment()}
                    onChange={(e) => {
                      if (e) {
                        setValueDate(e);
                      } else {
                        setValueDate(undefined);
                      }
                    }}
                    format="DD/MM/YYYY"
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        today: 'Hoje',
                      },
                    }}
                  />
                </label>

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2">
                  Período*
                  <TimePicker.RangePicker
                    name="time"
                    placeholder={['Hora de Início', 'Hora de Fim']}
                    format="HH:mm"
                    value={valueTimePeriod}
                    showNow={true}
                    order={false}
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        now: 'Agora',
                        ok: 'Confirmar',
                      },
                    }}
                    onChange={(e, v) => {
                      if (e) {
                        setValueTimePeriod(e);
                      } else {
                        setValueTimePeriod(undefined);
                      }
                    }}
                  />
                </label>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>Local de trabalho*</label>
                  <div className="d-flex form-radio justify-content-between">
                    <label
                      className="form-check-label d-flex align-items-center"
                      htmlFor="inPerson"
                    >
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="choice"
                        value="true"
                        id="inPerson"
                        defaultChecked={true}
                      />
                      Presencial
                    </label>
                    <label
                      className="form-check-label d-flex align-items-center me-2"
                      htmlFor="homeOffice"
                    >
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="choice"
                        value=""
                        id="homeOffice"
                      />
                      Home Office
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <label className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" htmlFor="title">
                  Descrição de Atividade*
                  <textarea name="activityDescription" rows="4" />
                </label>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div className="col">
                    {!isEdit && (
                      <div className="row button-container d-flex justify-content-between">
                        <button
                          id="save"
                          type="button"
                          className="btn btn-success col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-4"
                          onClick={() => {
                            appointmentInsert();
                          }}
                        >
                          Salvar
                        </button>
                        <button
                          id="clear"
                          type="button"
                          className="btn btn-danger-outline col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-4"
                          onClick={() => {
                            clearFields();
                          }}
                        >
                          Limpar
                        </button>
                      </div>
                    )}
                    {isEdit && (
                      <div className="row button-container d-flex justify-content-between">
                        <button
                          id="save"
                          type="button"
                          className="btn btn-success col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-4"
                          onClick={() => {
                            appointmentInsert();
                          }}
                        >
                          Salvar Edição
                        </button>
                        <button
                          id="clear"
                          type="button"
                          className="btn btn-danger-outline col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 mt-4"
                          onClick={() => {
                            clearFields();
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Registro Apontamento */}
          <div className="card mt-3">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Horas Apontadas</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="card-body">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 me-3 mb-3">
                Colaborador
                {valueCollaboratorFilter && (
                  <Select
                    showSearch
                    placeholder="Selecione..."
                    allowClear
                    defaultValue={valueCollaboratorFilter}
                    optionFilterProp="label"
                    options={stateCollaboratorOptions}
                    onChange={(e) => {
                      if (e !== undefined) {
                        setValueCollaboratorFilter(e);
                        getData(e, valueStartDate, valueEndDate);
                      }
                    }}
                  />
                )}
                {!valueCollaboratorFilter && <Select loading />}
              </label>

              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-3">
                Período
                <RangePicker
                  ranges={{
                    Hoje: [moment(), moment()],
                    'Mês Atual': [moment().startOf('month'), moment().endOf('month')],
                    'Mês Anterior': [
                      moment().subtract(1, 'months').startOf('month'),
                      moment().subtract(1, 'months').endOf('month'),
                    ],
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={rangePickerHandler}
                  defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                  format="DD/MM/YYYY"
                />
              </label>

              {/* Grid */}
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={tableData}
                nameOfResquest="Appointments"
                setData={setTableData}
                cdPage="cd_appointment"
                notEdit
                notCode
                selection={(record) => {
                  setAppointmentSelected(record);
                }}
                routePermissions="appointments"
                route="Appointments/GetGrid"
                getData={() => getData(valueCollaboratorFilter, valueStartDate, valueEndDate)}
                loadingTable={loadingTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';

import './style.scss';

export const datePickerRef = {};
let isDateSetted = false;
export default function CompDatePicker(props) {
  const { initialDate, minDate, isEdit } = props;
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (isEdit) {
      if (!isDateSetted && initialDate !== undefined) {
        isDateSetted = true;
        setStartDate(initialDate);
      }
    }
  }, [initialDate]);

  return (
    <div>
      <DatePicker
        value={startDate}
        ref={(ref) => {
          Object.assign(datePickerRef, ref);
        }}
        onChange={(date) => {
          setStartDate(date);
        }}
        dateFormat="dd/MM/y"
        minDate={minDate}
        prop={startDate}
        locale="pt-BR"
      />
    </div>
  );
}

CompDatePicker.propTypes = {
  initialDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  isEdit: PropTypes.bool.isRequired,
};

CompDatePicker.defaultProps = {
  minDate: new Date(),
};

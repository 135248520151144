import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './style.scss';

export default class DynamicCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      funcAddNew: props.funcAddNew,
      funcUpdtState: props.funcUpdtState,
      funcFillFields: props.funcFillFields,
      funcEditCard: props.funcEditCard,
      funcClearFields: props.funcClearFields,
      rightCardContent: props.rightCardContent,
      // eslint-disable-next-line react/prop-types
      isEdit: props.isEdit || false,
    };
    this.fields = props.fields;
    this.rightCardTitle = props.rightCardTitle;
    this.cardName = props.cardName;
  }

  componentDidMount() {
    const contentCardStyle = document.getElementById(`contentCard__${this.cardName}`).style;
    contentCardStyle.maxHeight = `${
      document.getElementById(`fieldsCard__${this.cardName}`).offsetHeight
    }px`;
  }

  // Destaca o card selecionado
  // eslint-disable-next-line class-methods-use-this
  selectCard(cards, selectedCardId) {
    for (let i = 0; i < cards.length; i += 1) {
      cards[i].classList.remove('content-card__selected');
    }
    if (cards[selectedCardId] !== undefined) {
      cards[selectedCardId].classList.add('content-card__selected');
    }
  }

  // Remove o card selecionado da lista de cards
  removeCard(id) {
    const { rightCardContent, funcUpdtState } = this.state;
    const currentId = document.getElementById(`${this.cardName}__current-id`);
    const cards = document.getElementById(`contentCard__${this.cardName}`).lastChild.firstChild
      .children;

    if (currentId.value !== id) {
      rightCardContent.splice(id, 1);
      this.setState((prevState) => ({ rightCardContent: prevState.rightCardContent }));
      funcUpdtState();
    }

    if (currentId.value > id) {
      currentId.value = parseInt(currentId.value, 10) - 1;
    }

    this.selectCard(cards, currentId.value);
  }

  // Limpa todos os campos
  clearAll() {
    const { funcClearFields } = this.state;
    const cards = document.getElementById(`contentCard__${this.cardName}`).lastChild.firstChild
      .children;

    funcClearFields();
    document.getElementById(`${this.cardName}__current-id`).value = '';

    for (let i = 0; i < cards.length; i += 1) {
      cards[i].classList.remove('content-card__selected');
    }
  }

  render() {
    const { rightCardContent, funcAddNew, funcFillFields, funcEditCard, isEdit } = this.state;
    return (
      <>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div id={`fieldsCard__${this.cardName}`} className="card dynamic-card">
            <div className="card-body dynamic-card-body">
              <input id={`${this.cardName}__current-id`} type="text" style={{ display: 'none' }} />
              {this.fields}

              {isEdit ? (
                <div className="dynamic-card__buttons mt-4">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      funcEditCard(document.getElementById(`${this.cardName}__current-id`).value);
                      this.setState({ isEdit: false });
                      this.clearAll();
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        funcEditCard(document.getElementById(`${this.cardName}__current-id`).value);
                        this.setState({ isEdit: false });
                        this.clearAll();
                      }
                    }}
                  >
                    Salvar
                  </button>

                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => {
                      this.clearAll();
                      this.setState({ isEdit: false });
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.clearAll();
                        this.setState({ isEdit: false });
                      }
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              ) : (
                <div className="dynamic-card__buttons mt-4">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      funcAddNew();
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        funcAddNew();
                      }
                    }}
                  >
                    Adicionar
                  </button>

                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => {
                      this.clearAll();
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.clearAll();
                      }
                    }}
                  >
                    Limpar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div
            id={`contentCard__${this.cardName}`}
            className="card dynamic-card dynamic-card__content"
          >
            <div className="card-header">
              <h2>{this.rightCardTitle}</h2>
            </div>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              <div className="card-body">
                {rightCardContent?.map((item, index) => (
                  <div
                    id={index}
                    className="card content-card"
                    onClick={(e) => {
                      funcFillFields(e.currentTarget.id, `${this.cardName}__current-id`);
                      this.selectCard(e.currentTarget.parentNode.children, e.currentTarget.id);
                      this.setState({ isEdit: true });
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        funcFillFields(e.currentTarget.id, `${this.cardName}__current-id`);
                        this.selectCard(e.currentTarget.parentNode.children, e.currentTarget.id);
                        this.setState({ isEdit: true });
                      }
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <div className="card-body">
                      <button type="button" className="remove-card-button">
                        <FaTimes
                          onClick={(e) => {
                            e.stopPropagation();
                            this.removeCard(e.currentTarget.parentNode.parentNode.parentNode.id);
                          }}
                        />
                      </button>
                      {item.content}
                    </div>
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </>
    );
  }
}

DynamicCard.propTypes = {
  funcAddNew: PropTypes.func.isRequired,
  funcUpdtState: PropTypes.func.isRequired,
  funcFillFields: PropTypes.func.isRequired,
  funcEditCard: PropTypes.func.isRequired,
  funcClearFields: PropTypes.func.isRequired,
  fields: PropTypes.element.isRequired,
  rightCardTitle: PropTypes.string.isRequired,
  rightCardContent: PropTypes.shape.isRequired,
  cardName: PropTypes.string.isRequired,
};

import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaRegClock } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppointmentApi } from '../../../../services/api';
import { validateEmail } from '../../assets/scripts/global';

import './style.scss';

export default function Contact() {
  //const alert = useAlert();

  <script $data-b24-form="inline/5/kslokz" $data-skip-moving="true">
    {function(w, d, u) {
    var s = d.createElement('script');
    s.async = true;
    s.src = u + '?' + (Date.now() / 180000 | 0);
    var h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  }}{(window, document, 'https://cdn.bitrix24.com.br/b23168269/crm/form/loader_5.js')}
  </script>


  function clearFields() {
    document.getElementById('name').value = '';
    document.getElementById('email').value = '';
    document.getElementById('subject').value = '';
    document.getElementById('message').value = '';
  }

  // Envia email para o "Contato"
  async function SendEmail() {
    document.getElementById('send').disabled = true;

    // Dados a ser enviados
    const data = JSON.stringify({
      type: 'email',
      subject: document.getElementById('subject').value,
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      message: document.getElementById('message').value,
      path: 0
    });

    // Requisição
    AppointmentApi.post('/Emails/Contact', data)
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => {
        clearFields();
        document.getElementById('send').disabled = false;
      });
  }

  function validateFields() {
    let isValid = true;
    const nameField = document.getElementById('name');
    const emailField = document.getElementById('email');
    const subjectField = document.getElementById('subject');
    const messageField = document.getElementById('message');

    if (nameField.value === '') {
      nameField.classList.add('border-danger');
      isValid = false;
    } else {
      nameField.classList.remove('border-danger');
    }

    if (emailField.value === '') {
      emailField.classList.add('border-danger');
      isValid = false;
    } else if (validateEmail(emailField.value)) {
      emailField.classList.remove('border-danger');
    } else {
      emailField.classList.add('border-danger');
      toast.error('E-mail inválido!');
      document.getElementById('send').disabled = false;
      return false;
    }

    if (subjectField.value === '') {
      subjectField.classList.add('border-danger');
      isValid = false;
    } else {
      subjectField.classList.remove('border-danger');
    }

    if (messageField.value === '') {
      messageField.classList.add('border-danger');
      isValid = false;
    } else {
      messageField.classList.remove('border-danger');
    }

    if (isValid) {
      SendEmail();
    } else {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  return (
    <>
      <ToastContainer />
      <div className="container contact">
        <div className="row align-items-start">
          <script>
            <div>
              {function(w, d, u) {
                var s = d.createElement('script');
                s.async = true;
                s.src = u + '?' + (Date.now() / 60000 | 0);
                var h = d.getElementsByTagName('script')[0];
                h.parentNode.insertBefore(s, h);
              }}{(window, document, 'https://cdn.bitrix24.com.br/b23168269/crm/site_button/loader_1_ywi41q.js')};
            </div>
          </script>

          {/* Form */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 fadeInLeft">
            <h2 className="title-3">FALE CONOSCO</h2>
            <p className="subtitle-2">Nosso time retornará contato o quanto antes</p>
            <div className="mb-3 mt-md-5">
              <label className="form-label" htmlFor="name">
                Nome completo*
                <input type="text" className="form-control" id="name" />
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                E-mail*
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="exemplo@email.com"
                />
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="subject">
                Assunto*
                <input type="text" className="form-control" id="subject" />
              </label>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="message">
                Mensagem*
                <textarea className="form-control" id="message" rows="8" />
              </label>
            </div>
            <div className="d-grid gap-2">
              <button
                id="send"
                type="button"
                className="btn btn-primary d-block"
                onClick={validateFields}
              >
                Enviar
              </button>
            </div>
          </div>

          {/* Address */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 fadeIn address">
            <h2 className="title-4">MATRIZ GASPAR</h2>
            <div className="text-center pt-3">
              <div className="d-flex flex-row text-start">
                <div>
                  <FaMapMarkerAlt size="24" color="#36b4e5" className="me-2" />
                </div>
                <p className="mb-3">
                  <span className="me-1 text-highlight-2">Endereço: </span>
                  <a
                    href="https://www.google.com/maps/place/NBW+Digital/@-26.9250964,-48.9600336,18.11z/data=!4m5!3m4!1s0x94df25227540538f:0x95ed32d01cf47242!8m2!3d-26.9249947!4d-48.9590703"
                    target="_blank"
                    rel="noreferrer"
                    title="Ir para Google Maps"
                  >
                    Rua Hercílio Fides Zimmermann, 285, 1º Andar, Margem Esquerda, Gaspar - SC,
                    89116-650
                  </a>
                </p>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <FaEnvelope size="24" color="#36b4e5" className="me-2" />
                </div>
                <p className="mb-3">
                  <span className="me-1 text-highlight-2">Email: </span>
                  <a
                    href="comercial@glatzon.com.br"
                    target="_blank"
                    rel="noreferrer"
                    title="Abrir email"
                  >
                    comercial@glatzon.com.br
                  </a>
                </p>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <FaPhone size="24" color="#36b4e5" className="me-2" />
                </div>
                <p className="mb-2">
                  <span className="me-1 text-highlight-2">Telefone: </span>
                  <a
                    href="https://api.whatsapp.com/send?phone=554733044033&text=Oi,%20tudo%20bem?"
                    target="_blank"
                    rel="noreferrer"
                    title="Whatsapp"
                  >
                    (47) 3304-4033
                  </a>
                </p>
              </div>

              <hr className="my-5" />

              <h2 className="title-4 text-start">HORÁRIO DE FUNCIONAMENTO</h2>
              <div className="d-flex flex-row mt-4 text-start">
                <div>
                  <FaRegClock size="24" color="#36b4e5" className="me-2" />
                </div>
                <p className="mb-3">Segunda a Sexta: 08:00 - 18:00</p>
              </div>
              <div className="d-flex flex-row text-start">
                <div>
                  <FaRegClock size="24" color="#36b4e5" className="me-2" />
                </div>
                <p className="mb-3">Sábados e Domingos: Plantão operacional</p>
              </div>
              <div className="d-flex flex-row text-start">
                <div>
                  <FaRegClock size="24" color="#36b4e5" className="me-2" />
                </div>
                <p className="mb-3">Feriados: Plantão operacional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}




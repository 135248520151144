import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { FaAngleLeft } from 'react-icons/fa';

import './style.scss';

export default function CompCollapsible(props) {
  const { title, content } = props;

  return (
    <Collapsible
      overflowWhenOpen="visible"
      triggerOpenedClassName="arrow-open"
      trigger={
        <>
          {title}
          <FaAngleLeft size="20" color="#fff" />
        </>
      }
      transitionTime={100}
      className="mt-4"
      openedClassName="mt-4"
    >
      {content}
    </Collapsible>
  );
}

CompCollapsible.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
};

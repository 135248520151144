import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';
import { AppointmentApi } from '../../../../../services/api';
import './style.scss';
import TableAntd from '../../../components/TableAntd';

export default function ProfilesList() {
    const [data, setData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);

    const getData = async () => {
        setLoadingTable(true);
        try {
            const response = await AppointmentApi.get('/Profiles');
            setData(response.data.map((item) => ({ ...item, key: item.cd_profile })));
        } catch (err) {
            if (!err.response) {
                toast.error('Servidor indisponível!');
            } else {
                toast.error('Erro ao carregar dados: ' + err.response.data.message);
            }
            setData([]);
        } finally {
            setLoadingTable(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'st_name',
        title: 'Nome',
        key: 'st_name',
        ...getColumnSearchProps('st_name', 'Nome'),
        sorter: (a, b) => a.st_name.length - b.st_name.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
      {
        dataIndex: 'st_description',
        title: 'Descrição',
        key: 'st_description',
        ...getColumnSearchProps('st_description', 'Descrição'),
        sorter: (a, b) => a.st_description.length - b.st_description.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
    ];
    return columns;
  };

  return (
    <>
     <ToastContainer />
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              {/* <h2 className="title-2 text-color-light text-center p-2 m-0">Perfis</h2> */}
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Perfis</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Grid */}
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={data}
                nameOfResquest="profiles"
                btnNew
                setData={setData}
                cdPage="cd_profile"
                routePermissions="profiles"
                route="Perfis/Registro"
                getData={() => getData()}
                loadingTable={loadingTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaDesktop, FaUserLock, FaCalendarAlt } from 'react-icons/fa';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
//import { Sidebar } from 'react-sidebar';
import PerfectScrollbar from 'react-perfect-scrollbar';

import '../../../../custom.scss';
import '../../admin.scss';
import './style.scss';

export default function SidebarMenu(props) {
    //Coloca margin top no primeiro módulo do menu lateral
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem('nbw__permissions'));
    if (window.location.pathname !== '/Login') {
      const menuElement = document.getElementById('menu-mt');
      if (menuElement !== undefined) {
        menuElement.style.marginTop = '62px'; 
      }
      return (
        <>
          <Sidebar id="sidebarMenu" collapsed={props.collapsed}>
            <PerfectScrollbar className="sidemenu-scrollbar" options={{ wheelPropagation: false }}>
              <Menu popperArrow>
                {(permissions[18].insert || permissions[17].view || permissions[26].view) && (
                  <SubMenu className="menu-mt" title="Admin" icon={<FaUserLock />}>
                    {permissions[18].view && (
                      <SubMenu title="Perfis">
                        <MenuItem>
                          Listar
                          <Link to="/Admin/Perfis" />
                        </MenuItem>
                        {permissions[18].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Admin/Perfis/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[17].view && (
                      <MenuItem>
                        Permissões
                        <Link to="/Admin/Permissoes" />
                      </MenuItem>
                    )}
                    {permissions[24].view && (
                      <SubMenu title="Regras">
                        <MenuItem>
                          Listar
                          <Link to="/Admin/Regras" />
                        </MenuItem>
                        {permissions[26].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Admin/Regras/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[26].view && (
                      <SubMenu title="Usuários">
                        <MenuItem>
                          Listar
                          <Link to="/Admin/Usuarios" />
                        </MenuItem>
                        {permissions[26].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Admin/Usuarios/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                  </SubMenu>
                )}
                {permissions[2].view && (
                  <SubMenu className="menu-mt" title="Apontamento" icon={<FaCalendarAlt />}>
                    <MenuItem>
                      Apontamento
                      <Link to="/Apontamento" />
                    </MenuItem>
                    {permissions[12].view && (
                      <SubMenu title="Cargos">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/Cargo" />
                        </MenuItem>
                        {permissions[12].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/Cargo/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[6].view && (
                      <SubMenu title="Colaboradores">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/Colaboradores" />
                        </MenuItem>
                        {permissions[6].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/Colaboradores/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}

                    {permissions[13].view && (
                      <SubMenu title="Pessoa Jurídica">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/PessoaJuridica" />
                        </MenuItem>
                        {permissions[13].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/PessoaJuridica/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[19].view && (
                      <SubMenu title="Projetos">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/Projeto" />
                        </MenuItem>
                        {permissions[19].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/Projeto/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[10].view && (
                      <SubMenu title="Tipo de Contato">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/TipoContato" />
                        </MenuItem>
                        {permissions[10].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/TipoContato/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}

                    {permissions[1].view && (
                      <SubMenu title="Tipo de Endereço">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/TipoEndereco" />
                        </MenuItem>
                        {permissions[1].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/TipoEndereco/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[14].view && (
                      <SubMenu title="Tipo de PJ">
                        <MenuItem>
                          Listar
                          <Link to="/Apontamento/TipoPJ" />
                        </MenuItem>
                        {permissions[14].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Apontamento/TipoPJ/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                  </SubMenu>
                )}

                {(permissions[27].view ||
                  permissions[5].view ||
                  permissions[15].view ||
                  permissions[22].view) && (
                  <SubMenu className="menu-mt" title="Recrutamento" icon={<FaDesktop />}>
                    {permissions[5].view && (
                      <SubMenu title="Candidatos">
                        <MenuItem>
                          Listar
                          <Link to="/Recrutamento/Candidatos" />
                        </MenuItem>
                        {permissions[5].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Recrutamento/Candidatos/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[15].view && (
                      <SubMenu title="Níveis">
                        <MenuItem>
                          Listar
                          <Link to="/Recrutamento/Niveis" />
                        </MenuItem>
                        {permissions[15].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Recrutamento/Niveis/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[22].view && (
                      <SubMenu title="Requisitos">
                        <MenuItem>
                          Listar
                          <Link to="/Recrutamento/Requisitos" />
                        </MenuItem>
                        {permissions[22].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Recrutamento/Requisitos/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                    {permissions[27].view && (
                      <SubMenu title="Vagas">
                        <MenuItem>
                          Listar
                          <Link to="/Recrutamento/Vagas" />
                        </MenuItem>
                        {permissions[27].insert && (
                          <MenuItem>
                            Cadastrar
                            <Link to="/Recrutamento/Vagas/Registro" />
                          </MenuItem>
                        )}
                      </SubMenu>
                    )}
                  </SubMenu>
                )}
              </Menu>
            </PerfectScrollbar>
          </Sidebar>
        </>
      );
    }
    return null;
  }, []);
}


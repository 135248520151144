import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { scrollTop, excludeRequest } from '../../../../Admin/assets/scripts/global';
import { AppointmentApi } from '../../../../../services/api';

import './style.scss';

export default function ContactTypeRegister() {
  const history = useNavigate();
  const isEdit = history.location.search.split('=').length > 1;
  const id = isEdit ? history.location.search.split('=')[1] : null;

  // function fillFields
  function fillFields(data) {
    document.getElementById('descriptionField').value = data.st_description;
  }
  function getJobRole() {
    const data = {
      params: { id },
    };
    AppointmentApi.get('/JobRoles', data)
      .then((res) => {
        fillFields(res.data);
      })
      .catch((err) => err.response.data);
  }

  // clear fields
  function clearFields() {
    document.getElementById('descriptionField').value = '';
  }
  // function validateFields
  function validateFields() {
    let isValid = true;
    const description = document.getElementById('descriptionField');

    if (description.value === '') {
      description.classList.add('border-danger');
      isValid = false;
    } else {
      description.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Requisição insert
  function JobRoleInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a serem enviados
      const data = JSON.stringify({
        st_description: document.getElementById('descriptionField').value,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      });

      if (isEdit) {
        Object.assign(data, { cd_job_role: parseInt(id, 10) });
      }

      AppointmentApi({
        method: isEdit ? 'PUT' : 'POST',
        url: '/JobRoles',
        data,
      })
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data);
            document.getElementById('save').disabled = false;
            if (redirect) {
              history.push('/Apontamento/Cargo');
            } else {
              clearFields();
              scrollTop();

              if (isEdit) {
                document.getElementById('delete').disabled = false;
              } else {
                document.getElementById('saveAndNew').disabled = false;
              }
              document.getElementById('save').disabled = false;
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data);

          if (isEdit) {
            document.getElementById('delete').disabled = false;
          } else {
            document.getElementById('saveAndNew').disabled = false;
          }
          document.getElementById('save').disabled = false;
        });
    }
  }
  useEffect(() => {
    if (isEdit) {
      getJobRole();
    } else {
      clearFields();
    }
  });

  return (
    <>
    <ToastContainer />
      <div className="page-content collaborators-register">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header d-flex">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Apontamento/Cargo">Cargo</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? 'Edição' : 'Registro'} de Cargo</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-12"
                  htmlFor="descriptionField"
                >
                  Descrição
                  <input id="descriptionField" type="text" />
                </label>
              </div>

              {/* Row 11 */}
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      JobRoleInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        JobRoleInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    JobRoleInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      JobRoleInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest(id, 'JobRoles');
                    history.push('/Apontamento/Cargo');
                  }}
                >
                  Excluir
                </button>
                <button type="button" className="btn btn-default-outline" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Alerta de Resposta */}
        <div id="response" className="alert" style={{ display: 'none' }} role="alert" />
      </div>
    </>
  );
}

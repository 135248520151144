export default {
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  option: (base) => ({
    ...base,
    border: 0,
    backgroundColor: '#48494C',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '42px',
    '&:hover': {
      backgroundColor: '#3c3d3e',
      cursor: 'pointer',
      '.check-contrast input:not(:checked)': {
        backgroundColor: '#48494C !important',
        borderColor: '#48494C !important',
      },
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#aeafb7',
    '&:hover': {
      color: '#aeafb7',
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: '#aeafb7',
    '&:hover': {
      color: '#aeafb7',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#aeafb7',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: '#48494C',
    margin: '2px 0px 0px 0px',
    border: 0,
    borderRadius: '8px',
    boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
    zIndex: 3,
  }),
  input: (base) => ({
    ...base,
    height: '42px',
  }),
};

import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppointmentApi } from '../../../../../services/api';
import { scrollTop, excludeRequest } from '../../../assets/scripts/global';

import './style.scss';

export default function ProfilesRegister() {
  const history = useNavigate();
  const isEdit = history.location.search.split('=').length > 1;
  const id = isEdit ? history.location.search.split('=')[1] : null;

  function fillFields(data) {
    document.getElementById('name').value = data.st_name;
    document.getElementById('description').value = data.st_description;
  }

  function getProfile() {
    const data = {
      params: { id },
      user: JSON.parse(localStorage.getItem('nbw__userData')).id,
    };

    AppointmentApi.get('/Profiles', data)
      .then((res) => {
        fillFields(res.data);
      })
      .catch(() => toast.error('Erro ao buscar dados!'));
  }

  // Limpar campos
  function clearFields() {
    document.getElementById('name').value = '';
    document.getElementById('description').value = '';
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    const name = document.getElementById('name');
    const description = document.getElementById('description');

    if (name.value === '') {
      name.classList.add('border-danger');
      isValid = false;
    } else {
      name.classList.remove('border-danger');
    }

    if (description.value === '') {
      description.classList.add('border-danger');
      isValid = false;
    } else {
      description.classList.remove('border-danger');
    }

    if (!isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
    }

    return isValid;
  }

  // Requisição insert
  function ProfileInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;
      // Dados a ser enviados
      const data = JSON.stringify({
        st_name: document.getElementById('name').value,
        st_description: document.getElementById('description').value,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      });
      if (isEdit) {
        Object.assign(data, { cd_profile: parseInt(id, 10) });
      }

      // Requisição
      if (isEdit) {
        AppointmentApi.put('/Profiles', data)
          .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success(res.data);
              history.push('/Admin/Perfis');
            }
          })
          .catch((err) => {
            toast.error(err.response.data);
          })
          .finally(() => {
            document.getElementById('save').disabled = false;
            document.getElementById('delete').disabled = false;
          });
      } else {
        AppointmentApi.post('/Profiles', data)
          .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success(res.data);
              clearFields();
              if (redirect) {
                history.push('/Admin/Perfis');
              } else {
                scrollTop();
                document.getElementById('saveAndNew').disabled = false;
                document.getElementById('save').disabled = false;
              }
            }
          })
          .catch((err) => {
            toast.error(err.response.data);
            document.getElementById('saveAndNew').disabled = false;
            document.getElementById('save').disabled = false;
          });
      }
    }
  }

  useEffect(() => {
    if (isEdit) {
      getProfile();
    } else {
      clearFields();
    }
  });

  return (
    <>
    <ToastContainer />
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Admin/Perfis">Perfis</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Registro de Perfil</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              <div className="row">
                <label className="text-color-light" htmlFor="name">
                  Nome de Perfil
                  <input type="text" id="name" className="mt-2" />
                </label>
              </div>
              <div className="row mt-4">
                <label className="text-color-light" htmlFor="description">
                  Descrição
                  <textarea id="description" rows={5} className="mt-2" />
                </label>
              </div>
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      ProfileInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        ProfileInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    ProfileInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      ProfileInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal Excluir */}
        <div
          className="modal fade modal-custom"
          id="excludeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="excludeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-icon">
                <FaExclamationTriangle size="40" className="exclamation-triangle" />
              </div>
              <div className="modal-header">
                <h4 className="modal-title mt-5 mb-2 second-title">
                  Deseja realmente excluir este registro?
                </h4>
              </div>
              <div className="modal-body" />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    excludeRequest({ cd_profile: id }, 'Profiles', 0);
                    history.push('/Admin/Perfis');
                  }}
                >
                  Sim
                </button>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

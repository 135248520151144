import React from 'react';
import { RiFilePaper2Line, RiEyeLine, RiVipDiamondLine } from 'react-icons/ri';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import './style.scss';

import glatzonSquare from '../../assets/img/quadrado_nbw.png';
import logoGaspartec from '../../../Website/assets/img/AboutLogos/logoGaspartec.png';
import logoNbw from '../../../Website/assets/img/AboutLogos/logoNbw.png';
import logoSenior from '../../assets/img/AboutLogos/canalSenior.png';
import logoAcate from '../../assets/img/AboutLogos/logoAcate.png';
import logoBlusoft from '../../assets/img/AboutLogos/logoBlusoft.png';
//import logoNovaCoop from '../../assets/img/AboutLogos/logoNovaCoop.png';

export default function About() {
  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 849, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  function CustomRightArrow() {
    return false;
  }
  function CustomLeftArrow() {
    return false;
  }

  let animationExecuted = false;

  window.addEventListener(
    'scroll',
    () => {
      if (window.location.href.split('/')[3] === 'About') {
        const bannerMission = document.getElementById('bannerMission');
        if (window.scrollY > bannerMission.offsetTop - 800 && !animationExecuted) {
          bannerMission.classList.add('fadeIn');
          document.getElementById('mission').classList.add('slide-left');
          document.getElementById('values').classList.add('slide-right');

          animationExecuted = true;
        }
      }
    },
    false
  );
  return (
    <>
      {/* About */}
      <div className="container about">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <h2 className="title-3 fadeInLeft">SOBRE NÓS</h2>
            <p className="subtitle fadeInLeft text-start">CONHEÇA A GLATZON</p>
            <p className="text-justify fadeIn">
              Somos especialistas em sistemas para gestão empresarial, abrangendo soluções robustas como CRM (Customer
              Relationship Management), ERP (Enterprise Resource Planning) e HCM (Human Capital Management).
              Nossas soluções de CRM permitem uma gestão eficaz dos relacionamentos com clientes, otimizando processos
              de vendas e atendimento.
              Os sistemas ERP que oferecemos integram e automatizam diversas operações empresariais, desde finanças e
              contabilidade até logística e produção, proporcionando uma visão holística e unificada do negócio.
              Além disso, nossas soluções de HCM facilitam a gestão completa do ciclo de vida dos colaboradores, desde a
              atração e recrutamento até o desenvolvimento e retenção, promovendo um ambiente de trabalho mais eficiente
              e produtivo.
              Com nossa expertise, ajudamos as empresas a aprimorar seus processos, aumentar a produtividade e alcançar
              resultados excepcionais.
            </p>
            <p className="text-justify fadeIn">
              Nossa cultura corporativa é orientada pela busca incessante por novas alternativas e métodos para resolver
              desafios de maneira ágil, inovadora e sustentável.
              Acreditamos que a tecnologia deve ser uma aliada estratégica das empresas, impulsionando seu crescimento e
              competitividade no mercado.
            </p>
            <p className="text-justify fadeIn">
              Ao longo dos anos, temos nos dedicado a entender profundamente as demandas de nossos clientes e a oferecer
              soluções sob medida que se alinhem perfeitamente com seus objetivos.
              Nosso time de especialistas está constantemente se atualizando e aprimorando suas habilidades para
              garantir que estamos na vanguarda das tendências tecnológicas e das melhores práticas do setor.
            </p>
            <p className="text-justify fadeIn">
              Estamos comprometidos com a excelência e a inovação contínua, e trabalhamos incansavelmente para criar um ambiente de colaboração e confiança mútua com nossos clientes.
              Nossa missão é ser um parceiro estratégico confiável, fornecendo soluções tecnológicas que não apenas atendam, mas superem as expectativas e contribuam para o sucesso duradouro de cada negócio que atendemos.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
            <img className="slide-right" src={glatzonSquare} alt="Foto letreito logo Glatzon" />
          </div>
        </div>
      </div>

      {/* Mission, Vison, Values */}
      <div className="container banner-mission">
        <div className="container">
          <div className="row align-items-start" id="bannerMission">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" id="mission">
              <div className="d-flex flex-column align-items-center">
                <RiFilePaper2Line size="56" color="#fff" />
                <strong>Missão</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Fornecer soluções empresariais que colaborem na transformação dos negócios.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <div className="d-flex flex-column align-items-center">
                <RiEyeLine size="56" color="#fff" />
                <strong>Nossos Valores</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Excelência, Inovação, Sustentabilidade, Transparência, Colaboração.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" id="values">
              <div className="d-flex flex-column align-items-center">
                <RiVipDiamondLine size="56" color="#fff" />
                <strong>Visão</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Ser referência em transformação empresarial, reconhecida por formar um ecossistema de confiança e resultados com clientes, fornecedores e colaboradores.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Carousel partners */}
      <div className="container carousel-container">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <h2 className="title-3 fadeInLeft mb-5">NOSSOS PARCEIROS</h2>
          </div>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img src={logoSenior} height="70px" alt="Logo Senior  " />
            <img src={logoGaspartec} height="70px" alt="Logo Gaspartec" />
            <img src={logoAcate} height="70px" alt="Logo Acate" />
            <img src={logoBlusoft} height="70px" alt="Logo Blusoft" />
            <img src={logoNbw} height="70px" alt="Logo Nbw Digital" />
          </Carousel>
        </div>
      </div>
    </>
  );
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useHistory } from 'react-router-dom';
import { Select, Breadcrumb, Tooltip } from 'antd';
import { FaQuestionCircle } from 'react-icons/fa';
import { AppointmentApi } from '../../../../services/api';

export default function UsersRegister() {

  const [stateProfileOptions, setStateProfileOptions] = useState([]);
  const [rules, setRules] = useState([]);

  // States com os valores dos campos
  const [valueProfile, setValueProfile] = useState([]);

  // Get FieldTypes
  async function fillProfilesOptions() {
    await AppointmentApi.get('/Profiles/GetSelectOptions')
      .then((response) => {
        setStateProfileOptions(response.data);
      })
      .catch((err) => err);
  }

  async function getRules() {
    await AppointmentApi.get(`/Rules`).then((response) => {
      setRules(response.data);

      document.getElementById('retroactiveField').value = response.data[0]?.st_value;

      const rulesSplitted = response.data[1]?.st_value.split(';');
      const rulesId = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const date of rulesSplitted) {
        if (date !== ' ') {
          rulesId.push(parseInt(date, 10));
        }
      }
      setValueProfile(rulesId);
    });
  }

  // Concatena os valores do multiselect
  function concatenateMultiSelectValue(value) {
    let result = '';
    for (let index = 0; index < value.length; index += 1) {
      result += `${value[index]}; `;
    }

    return result;
  }

  // Request para inserir
  function RulesInsert(cdRule, value) {
    // Dados a ser enviados
    const data = {
      cd_rule: cdRule,
      st_value: value,
      cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
    };
    // Requisição
    AppointmentApi({
      method: 'PUT',
      url: '/Rules',
      data,
    })
      .then(async (res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  }

  useEffect(async () => {
    await getRules();
    await fillProfilesOptions();
  }, []);

  return (
    <>
    <ToastContainer />
      <div className="page-content user-register">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header d-flex justify-content">
              <Breadcrumb>
                <Breadcrumb.Item>Configurações</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5"
                  htmlFor="retroactiveField"
                >
                  {rules[0]?.st_name}
                  <Tooltip placement="top" title={rules[0]?.st_description} color="#36b4e5">
                    <FaQuestionCircle className="ms-1 mb-2" size="13" color="#fff" />
                  </Tooltip>
                  <span className="d-flex mt-1">
                    <input id="retroactiveField" type="text" />
                    <button
                      id="save"
                      className="btn btn-success ms-3"
                      type="button"
                      onClick={() => {
                        const val = document.getElementById('retroactiveField').value;
                        RulesInsert(rules[0]?.cd_rule, val);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          const val = document.getElementById('retroactiveField').value;
                          RulesInsert(rules[0]?.cd_rule, val);
                        }
                      }}
                    >
                      Salvar
                    </button>
                  </span>
                </label>
              </div>

              {/* Row 2 */}

              <div className="row mt-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5">
                  {rules[1]?.st_name}
                  <Tooltip placement="top" title={rules[1]?.st_description} color="#36b4e5">
                    <FaQuestionCircle className="ms-1 mb-2" size="13" color="#fff" />
                  </Tooltip>
                  <span className="d-flex mt-1">
                    {stateProfileOptions && (
                      <Select
                        name="fieldType"
                        showSearch
                        mode="multiple"
                        style={{
                          width: '100%',
                        }}
                        placeholder="Selecione..."
                        allowClear
                        value={valueProfile}
                        optionFilterProp="label"
                        options={stateProfileOptions}
                        onChange={(e) => {
                          if (e !== undefined) {
                            setValueProfile(e);
                          } else {
                            setValueProfile(undefined);
                          }
                        }}
                        maxTagCount="responsive"
                      />
                    )}
                    {!stateProfileOptions && <Select loading />}
                    <button
                      id="save"
                      className="btn btn-success ms-3"
                      type="button"
                      onClick={() => {
                        RulesInsert(rules[1]?.cd_rule, concatenateMultiSelectValue(valueProfile));
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          RulesInsert(rules[1]?.cd_rule, concatenateMultiSelectValue(valueProfile));
                        }
                      }}
                    >
                      Salvar
                    </button>
                  </span>
                </label>
              </div>

              {/* Row 4 */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { Outlet, BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';

// Imports Website
import NavbarWebsite from './Modules/Website/components/Navbar';
import FooterWebsite from './Modules/Website/components/Footer';
import HomeWebsite from './Modules/Website/pages/Home';
import About from './Modules/Website/pages/About';
import Services from './Modules/Website/pages/Services';
import Contact from './Modules/Website/pages/Contact';
import Carreers from './Modules/Website/pages/Carreers';
import CarreerDetails from './Modules/Website/pages/CarreerDetails';
import CarreerForm from './Modules/Website/pages/CarreerForm';

// Imports Admin
import NavbarAdmin from './Modules/Admin/components/Navbar';
import Login from './Modules/Admin/pages/Login';
import Error from './Modules/Admin/pages/Error';
import ProfilesList from './Modules/Admin/pages/Profiles/List';
import ProfilesRegister from './Modules/Admin/pages/Profiles/Register';
import Permissions from './Modules/Admin/pages/Permissions';
import RulesList from './Modules/Admin/pages/Rules/List';
import RulesRegister from './Modules/Admin/pages/Rules/Register';
import UsersList from './Modules/Admin/pages/Users/List';
import UsersRegister from './Modules/Admin/pages/Users/Register';
import Settings from './Modules/Admin/pages/Settings';

// Imports Dashboard
import DashboardHome from './Modules/Dashboard/pages/Home';
import UserProfile from './Modules/Dashboard/pages/UserProfile';
import CandidatesList from './Modules/Dashboard/pages/Candidates/List';
import CandidatesRegister from './Modules/Dashboard/pages/Candidates/Register';
import LevelsList from './Modules/Dashboard/pages/Levels/List';
import LevelsRegister from './Modules/Dashboard/pages/Levels/Register';
import RequirementsList from './Modules/Dashboard/pages/Requirements/List';
import RequirementsRegister from './Modules/Dashboard/pages/Requirements/Register';
import VacanciesList from './Modules/Dashboard/pages/Vacancies/List';
import VacanciesRegister from './Modules/Dashboard/pages/Vacancies/Register';

// Imports Appointment
import Appointment from './Modules/Appointment/pages/Appointment';
import Collaborators from './Modules/Appointment/pages/Collaborators/List';
import CollaboratorsRegister from './Modules/Appointment/pages/Collaborators/Register';
import AddressType from './Modules/Appointment/pages/AddressType/List';
import AddressTypeRegister from './Modules/Appointment/pages/AddressType/Register';
import ContactType from './Modules/Appointment/pages/ContactType/List';
import ContactTypeRegister from './Modules/Appointment/pages/ContactType/Register';
import JobRole from './Modules/Appointment/pages/JobRole/List';
import JobRoleRegister from './Modules/Appointment/pages/JobRole/Register';
import JuridicalPersonType from './Modules/Appointment/pages/JuridicalPersonType/List';
import JuridicalPersonTypeRegister from './Modules/Appointment/pages/JuridicalPersonType/Register';
import Project from './Modules/Appointment/pages/Project/List';
import ProjectRegister from './Modules/Appointment/pages/Project/Register';
import JuridicalPerson from './Modules/Appointment/pages/JuridicalPerson/List';
import JuridicalPersonRegister from './Modules/Appointment/pages/JuridicalPerson/Register';
import GenerateEmailSignature from './Modules/Appointment/pages/GenerateEmailSignature';

const WebsiteLayout = () => (
  <div className="website-style">
    <NavbarWebsite />
    <Outlet />
    <FooterWebsite />
  </div>
);

const AdminLayout = () => (
  <div className="admin-style">
    <NavbarAdmin />
    <Outlet />
  </div>
);

const AppRoutes = () => {
  const location = useLocation();
  let permissions = {};

  // Check for permissions in local storage
  if (localStorage.getItem('nbw__permissions')) {
    permissions = JSON.parse(localStorage.getItem('nbw__permissions'));
  }

  return (
    <Routes>
      <Route path="/" element={<WebsiteLayout />}>
        <Route index element={<HomeWebsite />} />
        <Route path="About" element={<About />} />
        <Route path="Services" element={<Services />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="/" element={<HomeWebsite />} />
        <Route path="Carreer-details" element={<CarreerDetails />} />
        <Route path="Carreer-form" element={<CarreerForm />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route path="/Login" element={<Login />} />
      <Route path="/Erro" element={<Error />} />

      {permissions && (
        <>
          <Route path="/Admin" element={<AdminLayout />}>
            {permissions[18]?.view && <Route path="Perfis" element={<ProfilesList />} />}
            {permissions[18]?.insert && <Route path="Perfis/registro" element={<ProfilesRegister />} />}
            {permissions[17]?.view && <Route path="Permissoes" element={<Permissions />} />}
            {permissions[24]?.view && <Route path="Regras" element={<RulesList />} />}
            {permissions[24]?.insert && <Route path="Regras/Registro" element={<RulesRegister />} />}
            {permissions[26]?.view && <Route path="Usuarios" element={<UsersList />} />}
            {permissions[26]?.insert && <Route path="Usuarios/Registro" element={<UsersRegister />} />}
            <Route path="*" element={<Navigate to="/Erro?error=404" />} />
          </Route>

          <Route path="/Dashboard" element={<AdminLayout />}>
            <Route path="/Dashboard" element={<DashboardHome />} />
            <Route path="User-Profile" element={<UserProfile />} />
            <Route path="*" element={<Navigate to="/Erro?error=404" />} />
          </Route>

          <Route path="/Recrutamento" element={<AdminLayout />}>
            {permissions[5]?.view && <Route path="Candidatos" element={<CandidatesList />} />}
            {permissions[5]?.insert && <Route path="Candidatos/Registro" element={<CandidatesRegister />} />}
            {permissions[15]?.view && <Route path="Niveis" element={<LevelsList />} />}
            {permissions[15]?.insert && <Route path="Niveis/Registro" element={<LevelsRegister />} />}
            {permissions[22]?.view && <Route path="Requisitos" element={<RequirementsList />} />}
            {permissions[22]?.insert && <Route path="Requisitos/Registro" element={<RequirementsRegister />} />}
            {permissions[27]?.view && <Route path="Vagas" element={<VacanciesList />} />}
            {permissions[27]?.insert && <Route path="Vagas/Registro" element={<VacanciesRegister />} />}
            <Route path="*" element={<Navigate to="/Erro?error=404" />} />
          </Route>

          <Route path="/Apontamento" element={<AdminLayout />}>
            {permissions[2]?.view && <Route path="/Apontamento" element={<Appointment />} />}
            {permissions[12]?.view && <Route path="Cargo" element={<JobRole />} />}
            {permissions[12]?.insert && <Route path="Cargo/registro" element={<JobRoleRegister />} />}
            {permissions[24]?.insert && <Route path="Configuracao" element={<Settings />} />}
            {permissions[6]?.view && <Route path="Colaboradores" element={<Collaborators />} />}
            {permissions[6]?.insert && <Route path="Colaboradores/Registro" element={<CollaboratorsRegister />} />}
            {permissions[13]?.view && <Route path="Pessoa-Juridica" element={<JuridicalPerson />} />}
            {permissions[13]?.insert && <Route path="Pessoa-Juridica/Registro" element={<JuridicalPersonRegister />} />}
            {permissions[19]?.view && <Route path="Projeto" element={<Project />} />}
            {permissions[19]?.insert && <Route path="Projeto/Registro" element={<ProjectRegister />} />}
            {permissions[10]?.view && <Route path="Tipo-Contato" element={<ContactType />} />}
            {permissions[10]?.insert && <Route path="Tipo-Contato/Registro" element={<ContactTypeRegister />} />}
            {permissions[1]?.view && <Route path="Tipo-Endereco" element={<AddressType />} />}
            {permissions[1]?.insert && <Route path="Tipo-Endereco/Registro" element={<AddressTypeRegister />} />}
            {permissions[14]?.view && <Route path="Tipo-pj" element={<JuridicalPersonType />} />}
            {permissions[14]?.insert && <Route path="Tipo-pj/Registro" element={<JuridicalPersonTypeRegister />} />}
            {permissions[29]?.view && <Route path="Assinatura-Email" element={<GenerateEmailSignature />} />}
            <Route path="*" element={<Navigate to="/Erro?error=404" />} />
          </Route>
        </>
      )}
    </Routes>
  );
};

export default function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Breadcrumb } from 'antd';
import { AdminApi } from '../../../../services/api';
import { validateEmail } from '../../../Admin/assets/scripts/global';
import CompDatePicker, { datePickerRef } from '../../../Admin/components/DatePicker';

export default function UserProfile() {

  const [stateDatePicker, setStateDatePicker] = useState();

  function fillFields(data) {
    document.getElementById('name').value = data.st_name;
    document.getElementById('username').value = data.st_login;
    document.getElementById('email').value = data.st_email;

    if (data.dt_birthday !== undefined) {
      const tempDate = data.dt_birthday.split('-').map((dateValue) => parseInt(dateValue, 10));
      setStateDatePicker(new Date(tempDate[0], tempDate[1] - 1, tempDate[2]));
    }
  }

  function getUser() {
    // Realiza o request
    const data = {
      params: { cd_user: localStorage.getItem('nbw_userId') },
      headers: { Token: localStorage.getItem('nbw_token') },
    };
    AdminApi.get('/UserController.php', data)
      .then((res) => {
        fillFields(res.data);
      })
      .catch(() => toast.error('Erro ao buscar dados!'));
  }

  function toggleEye() {
    const pswd = document.getElementById('password');

    document.getElementById('pswdEye').classList.toggle('is-normal');
    if (pswd.getAttribute('type') === 'password') {
      pswd.setAttribute('type', 'text');
    } else {
      pswd.setAttribute('type', 'password');
    }
  }
  function toggleEye2() {
    const pswd = document.getElementById('confirmPassword');

    document.getElementById('pswdEye2').classList.toggle('is-normal');
    if (pswd.getAttribute('type') === 'password') {
      pswd.setAttribute('type', 'text');
    } else {
      pswd.setAttribute('type', 'password');
    }
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    let mandatoryFields = true;
    const name = document.getElementById('nameField');
    const username = document.getElementById('usernameField');
    const email = document.getElementById('emailField');
    const cpf = document.getElementById('cpfField');
    const birthday = document.getElementsByClassName('react-date-picker__wrapper')[0];
    const password = document.getElementById('passwordField');
    const pswdEye = document.getElementById('pswdEye');
    const confirmPassword = document.getElementById('confirmPasswordField');
    const pswdEye2 = document.getElementById('pswdEye2');

    if (name.value === '') {
      name.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      name.classList.remove('border-danger');
    }

    if (username.value === '') {
      username.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      username.classList.remove('border-danger');
    }

    if (document.getElementById('emailField').value === '') {
      email.classList.add('border-danger');
      mandatoryFields = false;
    } else if (validateEmail(email.value)) {
      email.classList.remove('border-danger');
    } else {
      email.classList.add('border-danger');
      toast.error('E-mail inválido!');
      isValid = false;
    }

    if (cpf.value === '' || cpf.value.length < 14) {
      cpf.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      cpf.classList.remove('border-danger');
    }

    if (datePickerRef.props.value === undefined) {
      birthday.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      birthday.classList.remove('border-danger');
    }

    if (password.value === '' || confirmPassword.value !== password.value) {
      password.classList.add('border-danger');
      pswdEye.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      password.classList.remove('border-danger');
      pswdEye.classList.remove('border-danger');
    }

    if (confirmPassword.value === '' || confirmPassword.value !== password.value) {
      confirmPassword.classList.add('border-danger');
      pswdEye2.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      confirmPassword.classList.remove('border-danger');
      pswdEye2.classList.remove('border-danger');
    }

    if (!mandatoryFields) {
      toast.error('Campos obrigatórios não preenchidos!');
      isValid = false;
    } else {
      isValid = true;
    }
    if (confirmPassword.value !== password.value) {
      toast.error('Senhas não correspondem!');
      isValid = false;
    }

    return isValid;
  }

  function UserUpdate() {
    if (validateFields()) {
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      const data = {
        cd_user: localStorage.getItem('nbw_userId'),
        st_name: document.getElementById('name').value,
        st_login: document.getElementById('username').value,
        st_password: document.getElementById('password').value,
        dt_birthday: datePickerRef.props.value.toLocaleDateString(),
      };
      if (data.st_password === '' || data.st_password === undefined) {
        delete data.st_password;
      }

      // Opções da requisição
      const options = {
        headers: { Token: localStorage.getItem('nbw_token') },
      };
      // Requisição
      AdminApi.put('/UserController.php', data, options)
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data);
            document.getElementById('save').disabled = false;
          }
        })
        .catch((err) => {
          toast.error(err.response.data);
          document.getElementById('save').disabled = false;
        });
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
    <ToastContainer />
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Perfil do Usuário</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-color-light"
                  htmlFor="name"
                >
                  Nome completo
                  <input id="name" className="mt-2" type="text" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-color-light"
                  htmlFor="username"
                >
                  Login
                  <input id="username" className="mt-2" type="text" />
                </label>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="text-color-light form-label">Data de Nascimento</label>
                  <CompDatePicker
                    initialDate={stateDatePicker}
                    isEdit={true}
                    minDate={new Date(1900, 0, 1)}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-color-light"
                  htmlFor="email"
                >
                  E-mail
                  <input id="email" className="mt-2" type="text" disabled />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-color-light"
                  htmlFor="password"
                >
                  Senha
                  <div className="input-group pswd-eye">
                    <input id="password" type="password" className="form-control mt-2" />
                    <span
                      id="pswdEye"
                      className="input-group-text mt-2"
                      role="button"
                      tabIndex="0"
                      onClick={toggleEye}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          toggleEye();
                        }
                      }}
                    >
                      <FaEye className="normal-eye" />
                      <FaEyeSlash className="slashed-eye" />
                    </span>
                  </div>
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-color-light"
                  htmlFor="confirmPassword"
                >
                  Confirmação Senha
                  <div className="input-group pswd-eye">
                    <input id="confirmPassword" type="password" className="form-control mt-2" />
                    <span
                      id="pswdEye2"
                      className="input-group-text mt-2"
                      onClick={toggleEye2}
                      role="button"
                      tabIndex="0"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          toggleEye2();
                        }
                      }}
                    >
                      <FaEye className="normal-eye" />
                      <FaEyeSlash className="slashed-eye" />
                    </span>
                  </div>
                </label>
              </div>

              <div className="mt-4">
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    UserUpdate(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      UserUpdate(true);
                    }
                  }}
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { Select, Breadcrumb } from 'antd';
import { FaExclamationTriangle, FaChevronRight } from 'react-icons/fa';
import { AppointmentApi } from '../../../../../services/api';
import { scrollTop, excludeRequest } from '../../../assets/scripts/global';

export default function UsersRegister() {
  const history = useNavigate();
  const isEdit = history.location.search.split('=').length > 1;
  const id = isEdit ? history.location.search.split('=')[1] : null;

  const [stateProfileOptions, setStateProfileOptions] = useState([]);

  // States com os valores dos campos
  const [valueRule, setValueRule] = useState({ value: null });
  let optionsFilled = false;

  // Get Rules
  async function fillProfileOptions() {
    await AppointmentApi.get('/FieldTypes/GetSelectOptions')
      .then((response) => {
        setStateProfileOptions(response.data);
      })
      .catch((err) => err);
  }

  // Limpa o optionsFilled para realizar preenchimento dos campos novamente
  history.listen((location) => {
    if (location.pathname + location.search.split('=')[0] !== '/Admin/Regras/Registro?cd_rule') {
      optionsFilled = false;
    }
  });

  // Preenche os campos em editar
  function fillFields(data) {
    document.getElementById('nameField').value = data.st_name;
    document.getElementById('descriptionField').value = data.st_description;
    document.getElementById('valueField').value = data.st_value;
    setValueRule({ label: data.st_description_type, value: data.cd_field_type });
  }

  // Valida os campos
  function validateFields() {
    let isValid = true;
    let mandatoryFields = true;
    const name = document.getElementById('nameField');
    const description = document.getElementById('descriptionField');
    const fieldType = document.getElementsByName('fieldType')[0].firstChild;

    if (name.value === '') {
      name.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      name.classList.remove('border-danger');
    }

    if (description.value === '') {
      description.classList.add('border-danger');
      mandatoryFields = false;
    } else {
      description.classList.remove('border-danger');
    }

    if (valueRule === undefined || !valueRule || valueRule === null) {
      fieldType.classList.add('border-danger');
      isValid = false;
    } else {
      fieldType.classList.remove('border-danger');
    }

    if (!mandatoryFields || !isValid) {
      toast.error('Campos obrigatórios não preenchidos!');
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  }

  // Limpa os campos
  function clearFields() {
    document.getElementById('nameField').value = '';
    document.getElementById('descriptionField').value = '';
    document.getElementById('valueField').value = '';
    setValueRule(undefined);
  }

  async function getDataRules() {
    AppointmentApi.get('Rules', {
      params: {
        id,
      },
    }).then((res) => {
      fillFields(res.data);
    });
  }

  // Request para inserir
  function RulesInsert(redirect) {
    if (validateFields()) {
      if (!isEdit) {
        document.getElementById('saveAndNew').disabled = true;
      } else {
        document.getElementById('delete').disabled = true;
      }
      document.getElementById('save').disabled = true;

      // Dados a ser enviados
      const data = {
        st_name: document.getElementById('nameField').value,
        st_description: document.getElementById('descriptionField').value,
        st_value: document.getElementById('valueField').value,
        cd_field_type: valueRule.value,
        cd_user: JSON.parse(localStorage.getItem('nbw__userData')).id,
      };

      if (isEdit) {
        Object.assign(data, {
          cd_rule: parseInt(id, 10),
        });
      }

      // Requisição
      AppointmentApi({
        method: isEdit ? 'PUT' : 'POST',
        url: '/Rules',
        data,
      })
        .then(async (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data);
            document.getElementById('save').disabled = false;
            if (redirect) {
              history.push('/Admin/Regras');
            } else {
              scrollTop();
              clearFields();
              if (isEdit) {
                document.getElementById('delete').disabled = false;
              } else {
                document.getElementById('saveAndNew').disabled = false;
              }
              document.getElementById('save').disabled = false;
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data);

          if (isEdit) {
            document.getElementById('delete').disabled = false;
          } else {
            document.getElementById('saveAndNew').disabled = false;
          }
          document.getElementById('save').disabled = false;
        });
    }
  }

  useEffect(async () => {
    if (!optionsFilled) {
      await fillProfileOptions();
      optionsFilled = true;
    }
  }, [optionsFilled]);

  useEffect(async () => {
    if (isEdit) {
      getDataRules();
    }
  }, []);

  return (
    <>
    <ToastContainer />
      <div className="page-content user-register">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-12">
          <div className="card">
            <div className="card-header d-flex justify-content">
              <Breadcrumb separator={<FaChevronRight className="ms-1 me-1" />}>
                <Breadcrumb.Item>
                  <Link to="/Admin/Regras">Regras</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{isEdit ? 'Edição' : 'Registro'} de Regras</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Row 1 */}
              <div className="row">
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="nameField"
                >
                  Nome
                  <input id="nameField" type="text" />
                </label>
                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="descriptionField"
                >
                  Descrição
                  <input id="descriptionField" type="text" />
                </label>

                <label
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  htmlFor="valueField"
                >
                  Valor
                  <input id="valueField" type="text" />
                </label>
              </div>

              {/* Row 2 */}
              <div className="row mt-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  Tipo
                  {stateProfileOptions && (
                    <Select
                      name="fieldType"
                      showSearch
                      placeholder="Selecione..."
                      allowClear
                      value={valueRule}
                      optionFilterProp="label"
                      options={stateProfileOptions}
                      onChange={(e, value) => {
                        if (e !== undefined) {
                          setValueRule(value);
                        } else {
                          setValueRule(undefined);
                        }
                      }}
                    />
                  )}
                  {!stateProfileOptions && <Select loading />}
                </label>
              </div>

              {/* Row 4 */}
              <div className="mt-4 d-flex">
                {!isEdit ? (
                  <button
                    id="saveAndNew"
                    className="btn btn-success me-3"
                    type="button"
                    onClick={() => {
                      RulesInsert(false);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        RulesInsert(false);
                      }
                    }}
                  >
                    Salvar & Novo
                  </button>
                ) : (
                  <></>
                )}
                <button
                  id="save"
                  className="btn btn-success me-3"
                  type="button"
                  onClick={() => {
                    RulesInsert(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      RulesInsert(true);
                    }
                  }}
                >
                  Salvar
                </button>
                {isEdit ? (
                  <button
                    id="delete"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#excludeModal"
                    type="button"
                  >
                    Excluir
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Excluir */}
      <div
        className="modal fade modal-custom"
        id="excludeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="excludeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-icon">
              <FaExclamationTriangle size="40" className="exclamation-triangle" />
            </div>
            <div className="modal-header">
              <h4 className="modal-title mt-5 mb-2 second-title">
                Deseja realmente excluir este registro?
              </h4>
            </div>
            <div className="modal-body" />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  excludeRequest(id, 'Rules');
                  history.push('/Admin/Regras');
                }}
              >
                Excluir
              </button>
              <button type="button" className="btn btn-default-outline" data-bs-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useCookies } from 'react-cookie';
import { GoLog } from "react-icons/go";
import '../../Admin/assets/scripts/global.js';

const CookieConsent = () => {
  const [cookies, setCookie, save] = useCookies(['cookieConsent']);
  const giveCookieConsent = () => {
    setCookie('cookieConsent', true, { maxAge: 259200 }, { path: "/" });
  };


  const [isModalOpen, setIsModalOpen] = useState(true);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    giveCookieConsent();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal title="Uso de Cookies" open={isModalOpen} okText='Aceito' onOk={handleOk} onCancel={handleCancel}
             cancelText='Recusar'>
        <p>Nós usamos cookies para melhorar sua experiência de usuário.
          Ao utilizar nosso site, você concorda com o uso de cookies.
        </p>
        <a href={"#modalPoliticaDePrivacidade"} className="nav-link px-md-2 px-1" data-bs-toggle="modal">
          <strong><GoLog /> Consulte nossa Politica de Privacidade.</strong>
        </a>
      </Modal>
    </>
  );
};

export default CookieConsent;
// o modal #modalPoliticaDePrivacidade está no index da Pasta Footer

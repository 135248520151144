import React from 'react';
import './style.scss';
import animation from '../../../../img/animation.gif';
import animation403 from '../../../../img/animationForbidden.gif';

export default function Error() {
  const errorNumber = window.location.search.split('=')[1];

  return (
    <>
      <div className="page-content error-page">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
          {errorNumber === '403' && (
            <>
              <span className="error403">
                <h2>Acesso Negado</h2>
                <img
                  src={animation403}
                  className="forbidden"
                  title="NBW Digital"
                  alt="GIF 403 forbidden"
                />
              </span>
            </>
          )}
          {errorNumber === '404' && (
            <img
              src={animation}
              className="notFound"
              title="NBW Digital"
              alt="GIF 404 page not found"
            />
          )}
        </div>
      </div>
    </>
  );
}

import React from 'react';
import AppRoutes from './routes';

import CookieConsent from './Modules/Website/assets/CookieConsent';
import { useCookies } from 'react-cookie';

function App() {
  const [cookies] = useCookies(['cookieConsent']);
  return (
    <div className="app">
      {!cookies.cookieConsent && <CookieConsent />}
      <AppRoutes />
    </div>
  )
}

export default App;
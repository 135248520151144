/* eslint-disable prefer-destructuring */
//import { transitions, positions } from 'react-alert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-cycle
import { cnpj } from 'cpf-cnpj-validator';
import { AdminApi, AppointmentApi } from '../../../../services/api';

// Valida o e-mail
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Rola a página para o topo
export function scrollTop() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 10);
}

// Pegar cookies
export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// Deletar cookies
export function delCookies(isLogout, history) {
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }

  // Remove session
  localStorage.removeItem('nbw__userData');
  localStorage.removeItem('nbw__permissions');
  localStorage.removeItem('nbw__token');

  // Redireciona para Login
  if (isLogout) {
    history.push('/Login');
  }
}

// Verificar se existe cookie para manter logado
// eslint-disable-next-line consistent-return
export async function hasCookies() {
  if (getCookie('nbw__keepConnected') !== '') {
    if (!localStorage.getItem('nbw__token')) {
      const dataLog = JSON.stringify({
        St_login: getCookie('nbw__login'),
        St_password: getCookie('nbw__password'),
        Keep_connected: true,
      });

      // Requisição
      await AppointmentApi.post('/Login', dataLog)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            // Cria a "session"
            localStorage.setItem('nbw__userData', res.data.userData);
            localStorage.setItem('nbw__permissions', JSON.stringify(res.data.permissions));
            localStorage.setItem('nbw__token', res.data.token);

            if (localStorage.getItem('nbw__permissions')) {
              window.location.href = '/Dashboard';
            }
          }
        })
        .catch(() => false);
    } else {
      window.location.href = '/Dashboard';
    }
  } else if (localStorage.getItem('nbw__token')) {
    window.location.href = '/Dashboard';
  } else {
    delCookies();
    return false;
  }
}

// Request padrão para excluir um registro
export async function excludeRequest(id, controller) {
  const user = JSON.parse(localStorage.getItem('nbw__userData')).id;
  AppointmentApi.delete(`/${controller}?id=${id}&user=${user}`);
}

// Request padrão para editar um campo de um registro atravéz da tabela
export async function editRequest(data, controller, api) {
  const headers = { Token: localStorage.getItem('nbw_token') };
  // Requisição

  switch (api) {
    case 0:
      AdminApi({
        method: controller === 'Candidate' ? 'POST' : 'PUT',
        url: `/${controller}`,
        data,
        headers,
      });
      break;

    case 1:
      AppointmentApi({
        method: 'PUT',
        url: `/${controller}`,
        data,
      });
      break;

    default:
  }
}

// Request padrão para editar um campo de um registro atravéz da tabela
export async function getAllRequest(controller) {
  const options = {
    params: { totalItems: 99999 },
    headers: { Token: localStorage.getItem('nbw_token') },
  };
  // Requisição
  return AdminApi.get(`/${controller}Controller.php`, options);
}

export const alertOptions = {
  position: toast.TOP_RIGHT,
  timeout: 5000,
  transition: toast.FADE,
  containerStyle: {
    top: '70px',
    left: 'unset',
    right: '10px',
    zIndex: '5',
  },
};

export function buildRequestObject(otherFields, fields, apiKeys) {
  const data = {};
  let j = 0;

  if (fields) {
    for (let i = 0; i < fields.length; i += 1) {
      const el = fields[i];
      // Os if's abaixo montam o objeto para o request
      // Input Normal
      if (el.classList.contains('art__input') && (typeof el.value !== 'undefined' || el.value)) {
        data[apiKeys[i]] = el.value;
      }

      // Other Fields
      if (el.classList.contains('art__select') || el.classList.contains('art__datePicker')) {
        const field = otherFields[j];

        // Select
        if (field.type === 'select') {
          if (field.ref.select.getValue()[0] !== 'undefined' || field.ref.select.getValue()[0]) {
            data[apiKeys[i]] = field.ref.select.getValue()[0];
          }
        }

        // SelectAntd
        if (field.type === 'selectAntd') {
          if (field.ref !== 'undefined' || field.ref) {
            data[apiKeys[i]] = field.ref;
          }
        }

        // Date Picker
        if (field.type === 'datePicker') {
          if (field.ref.props.value) {
            data[apiKeys[i]] = field.ref.props.value.toLocaleDateString();
          }
        }

        // Time Picker
        if (field.type === 'timePicker') {
          if (!field.ref.value) {
            data[apiKeys[i]] = field.ref.value;
          }
        }

        j += 1;
      }
    }
  }
  return data;
}

/**
 * @description Função para validar formulário, preparar objeto para o request e limpar os campos.
 *
 * @param {array} [otherFields] Campos que utilizam "ref". Os campos devem estar na ordem do html (de cima para baixo).
 * @param {string} otherFields.type Tipo do campo
 * @param {Object} otherFields.ref Ref do campo
 * @param {array} apiKeys Nome dos campos que vão no request
 *
 * @returns {Object} Objeto contendo "status" (success ou error) e "response". Em response caso seja inválido retorna um array de mensagens de erro, e caso seja válido retorna um objeto pronto para um request.
 */
export async function validateForm(otherFields, setClear) {
  const fields = document.getElementsByClassName('art__form-field');
  const mandatoryFields = document.getElementsByClassName('art__mandatory');
  // let data = {};
  const errorMessages = [];
  let isFormValid = true;
  let j = 0;

  // Aqui são validados os campos obrigatórios
  if (mandatoryFields) {
    for (let i = 0; i < mandatoryFields.length; i += 1) {
      const el = mandatoryFields[i];

      // Input Normal
      if (el.classList.contains('art__input') && (typeof el.value === 'undefined' || !el.value)) {
        el.classList.add('border-danger');
        isFormValid = false;
      } else if (el.classList.contains('art__input')) {
        el.classList.remove('border-danger');
      }

      // Other Fields
      if (
        el.classList.contains('art__select') ||
        el.classList.contains('art__datePicker') ||
        el.classList.contains('art__timePicker')
      ) {
        const field = otherFields[j];

        // Select
        if (field.type === 'select') {
          if (field.ref.select.getValue()[0] === 'undefined' || !field.ref.select.getValue()[0]) {
            el.getElementsByTagName('div')[0].style.border = '1px #cf4141 solid';
            isFormValid = false;
          } else {
            el.getElementsByTagName('div')[0].style.border = '0px';
          }
        }

        // SelectAntd
        if (field.type === 'selectAntd') {
          if (field.ref === undefined || field.ref <= 0) {
            el.getElementsByTagName('div')[0].style.border = '1px #cf4141 solid';
            isFormValid = false;
          } else {
            el.getElementsByTagName('div')[0].style.border = '0px';
          }
        }

        // TimePicker
        if (field.type === 'timePicker') {
          if (field.ref === undefined || field.ref <= 0) {
            el.style.border = '1px #cf4141 solid';
            isFormValid = false;
          } else {
            el.style.border = '0px';
          }
        }

        // Date Picker
        if (field.type === 'datePicker') {
          if (field.ref === undefined || field.ref <= 0) {
            el.style.border = '1px #cf4141 solid';
            isFormValid = false;
          } else {
            el.getElementsByTagName('div')[0].style.border = '0px';
          }
        }

        // // Time Picker
        // if (field.type === 'timePicker') {
        //   if (!field.ref.value) {
        //     el.classList.add('border-danger');
        //     isFormValid = false;
        //   } else {
        //     el.classList.remove('border-danger');
        //   }
        // }

        j += 1;
      }
    }
  }

  // Se algum campo for inválido retorna as mensagens de erro
  if (isFormValid === false) {
    errorMessages.push('Campos obrigatórios não preenchidos!');

    return {
      status: 'error',
      response: errorMessages,
    };
  }

  // Chama função para montar objeto
  // data = await buildRequestObject(otherFields, fields, apiKeys);

  // Limpa os campos
  setTimeout(() => {
    if (fields) {
      for (let i = 0; i < fields.length; i += 1) {
        const el = fields[i];

        if (el.classList.contains('art__input')) {
          el.value = '';
        }
      }
    }
    for (let i = 0; i < otherFields.length; i += 1) {
      switch (otherFields[i].type) {
        case 'selectAntd':
          // eslint-disable-next-line no-unused-expressions
          // otherFields[i].ref;
          if (typeof setClear === 'function') {
            setClear(null);
            setTimeout(() => setClear(undefined), 100);
          }
          break;
        case 'select':
          // eslint-disable-next-line no-unused-expressions
          otherFields[i].ref;
          break;
        case 'datePicker':
          if (typeof setClear === 'function') {
            setClear(null);
            setTimeout(() => setClear(undefined), 100);
          }
          break;
        // case 'timePicker':
        //   otherFields[i].ref.handleValue(null);
        //   break;
        default:
          break;
      }
    }
  }, 500);

  // Retorna o objeto contendo os dados do formulário
  return {
    status: 'success',
    // response: data,
  };
}

// Valida CNPJ
export function validateCNPJ(cnpjValue) {
  let data = cnpjValue;
  data = cnpjValue.replace(/[^\d]+/g, '');

  const isValid = cnpj.isValid(data);
  return isValid;
}

import React from 'react';

import './style.scss';

export default function Home() {
  return (
    <>
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <h2 className="title-2 text-color-light text-center p-2 m-0">Dashboard</h2>
            </div>

            <div className="card-body" />
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaUsers,
  FaMugHot,
  FaChartLine,
  FaMedal,
  FaArrowRight,
  FaLightbulb,
  FaLayerGroup,
  FaCogs,
  FaDollarSign,
} from 'react-icons/fa';

import './style.scss';
import { scrollTop } from '../../assets/scripts/global';
import banner from '../../assets/img/banner.jpg';

let animationExecuted = false;

function animateValue(obj, start, end, duration, plus) {
  const object = obj;
  let startTimestamp = null;

  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    object.innerHTML = Math.floor(progress * (end - start) + start);

    if (plus) {
      object.innerHTML += '+';
    } else {
      object.innerHTML += '';
    }

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

window.addEventListener(
  'scroll',
  () => {
    if (window.location.href.split('/')[3] === '') {
      if (window.scrollY > 50) {
        document.getElementById('navbar').classList.add('navbar-black');
      } else {
        document.getElementById('navbar').classList.remove('navbar-black');
      }

      const hotNumbersPosition = document.getElementsByClassName('hot-numbers')[0].offsetTop;
      if (window.scrollY > hotNumbersPosition - 600 && !animationExecuted) {
        const users = document.getElementById('users');
        animateValue(users, 0, 30000, 2000, true);

        const years = document.getElementById('years');
        animateValue(years, 0, 10, 2000, false);

        const projects = document.getElementById('projects');
        animateValue(projects, 0, 900, 2000, true);

        const mugs = document.getElementById('mugs');
        animateValue(mugs, 0, 100, 2000, true);

        animationExecuted = true;
      }
    }
  },
  false
);

export default function Home() {
  return (
    <>
      {/* Banner */}
      <div className="banner">
        <img src={banner} alt="Banner home page" />
        <div className="d-flex text-center fadeIn banner-title">
          <h1 className="title-1 text-light">TRANSFORMAÇÃO DIGITAL </h1>
          <h1 className="title-1 text-light"> PARA OTIMIZAR RESULTADOS</h1>
        </div>
      </div>

      {/* Services Cards */}
      <div className="container services-cards">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaLightbulb size="56" color="#36b4e5" />
              <div className="card-body">
                <h5 className="card-title">INOVAÇÃO</h5>
                <p className="card-text">
                  Nossas soluções são desenhadas e construídas para melhor servir na necessidade.
                  Aliar nossa disposição com as técnicas mais inovadoras do mercado é garantia de
                  satisfação.
                </p>
                <Link to="/Services" onClick={scrollTop}>
                  <button type="button" className="btn btn-primary">
                    Saber mais <FaArrowRight size="24" color="#fff" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaLayerGroup size="56" color="#36b4e5" />
              <div className="card-body">
                <h5 className="card-title">MELHORIA CONTINUADA</h5>
                <p className="card-text">
                  Sempre há espaço para melhorar aquilo que já está bom. Buscar as melhores
                  alternativas para que os resultados sejam sempre maximizados faz parte de nosso
                  objetivo.
                </p>
                <Link to="/Services" onClick={scrollTop}>
                  <button type="button" className="btn btn-primary">
                    Saber mais <FaArrowRight size="24" color="#fff" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaCogs size="56" color="#36b4e5" />
              <div className="card-body">
                <h5 className="card-title">OTIMIZAÇÃO DE PROCESSOS</h5>
                <p className="card-text">
                  Processos administrativos e operacionais que são custosos e podem tomar mais tempo
                  que o necessário são os principais sintomas a serem combatidos na transformação
                  digital.
                </p>
                <Link to="/Services" onClick={scrollTop}>
                  <button type="button" className="btn btn-primary">
                    Saber mais <FaArrowRight size="24" color="#fff" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaDollarSign size="56" color="#36b4e5" />
              <div className="card-body">
                <h5 className="card-title">MELHOR CUSTO BENEFÍCIO</h5>
                <p className="card-text">
                  Com a GLATZON, a sua organização e os seus negócios terão a disposição um
                  verdadeiro arsenal digital. Buscamos sempre o melhor &quot;custo benefício&quot;
                  de acordo com o prazo e resultado desejados.
                </p>
                <Link to="/Services" onClick={scrollTop}>
                  <button type="button" className="btn btn-primary">
                    Saber mais <FaArrowRight size="24" color="#fff" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hot Numbers */}
      <div className="container">
        <div className="row align-items-start text-color-dark hot-numbers">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="d-flex flex-column align-items-center">
              <FaUsers size="56" color="#18355e" />
              <strong id="users">30000+</strong>
              <p className="custom-label mt-1 fadeIn">Usuários satisfeitos</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="d-flex flex-column align-items-center">
              <FaMedal size="56" color="#F0BE19" />
              <strong id="years">10</strong>
              <p className="custom-label mt-1 fadeIn">Anos de mercado</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="d-flex flex-column align-items-center">
              <FaChartLine size="56" color="#0CF65E" />
              <strong id="projects">900+</strong>
              <p className="custom-label mt-1 fadeIn">Projetos executados</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5">
            <div className="d-flex flex-column align-items-center">
              <FaMugHot size="56" color="#EB0E00" />
              <strong id="mugs">100+</strong>
              <p className="custom-label mt-1 fadeIn">Cafés consumidos por hora</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

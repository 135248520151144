import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumb } from 'antd';
import { AppointmentApi } from '../../../../../services/api';

import './style.scss';
import TableAntd from '../../../../Admin/components/TableAntd';

export default function LevelsList() {
  //const toast = usetoast();
  const [data, setData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getData = async () => {
    setLoadingTable(true);
    await AppointmentApi.get('/KnowledgeLevels')
      .then((res) => setData(res.data.map((item) => ({ ...item, key: item.cd_level }))))
      .catch((err) => {
        if (err.response === undefined) {
          toast.error('Servidor indisponível!');
          setData([]);
        }
        setData([]);
      });
    setLoadingTable(false);
  };

  useEffect(async () => {
    await getData();
  }, []);

  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'st_description',
        title: 'Descrição',
        key: 'st_description',
        ...getColumnSearchProps('st_description', 'Descrição'),
        sorter: (a, b) => a.st_description.length - b.st_description.length,
        sortDirections: ['descend', 'ascend'],
        editable: true,
      },
    ];
    return columns;
  };

  return (
    <>
     <ToastContainer />
      <div className="page-content">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>Níveis</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              {/* Grid */}
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={data}
                nameOfResquest="KnowledgeLevels"
                btnNew
                setData={setData}
                cdPage="cd_level"
                routePermissions="knowledge_levels"
                route="Niveis/Registro"
                getData={() => getData()}
                loadingTable={loadingTable}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

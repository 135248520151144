/* eslint-disable react/require-default-props */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export function MenuList(props) {
  const { children } = props;
  const itensDisplayedInMenu = 4;
  return (
    <div
      className="select_menuList"
      style={{ height: children.length * 42, maxHeight: itensDisplayedInMenu * 42 }}
    >
      <PerfectScrollbar options={{ wheelPropagation: false }}>{children}</PerfectScrollbar>
    </div>
  );
}

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Option = (props) => {
  const { isSelected, label } = props;
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check check-contrast">
          <label className="form-check-label" htmlFor="checkbox">
            <input
              className="form-check-input me-3"
              type="checkbox"
              onChange={() => null}
              checked={isSelected}
            />
            {label}
          </label>
        </div>
      </components.Option>
    </div>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    if (values.length > 1) {
      return (
        <components.ValueContainer {...props}>
          <span className="multiselect__values-select">{`(${values.length}) Opções`}</span>
          {input}
        </components.ValueContainer>
      );
    }
    if (values.length === 1) {
      return (
        <components.ValueContainer {...props}>
          <span className="multiselect__values-select">{`(${values.length}) Opção`}</span>
          {input}
        </components.ValueContainer>
      );
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  values: PropTypes.number,
  input: PropTypes.node,
};

import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Select as SelectAnd, Breadcrumb } from 'antd';
import { AppointmentApi } from '../../../../services/api';
import TableAntd from '../../components/TableAntd';
import './style.scss';

let optionsFilled = false;

export default function Permissions() {
  const history = useNavigate();
  const userData = JSON.parse(localStorage.getItem('nbw__userData'));
  const permissions = JSON.parse(localStorage.getItem('nbw__permissions'))[2];

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [stateProfileOptions, setStateProfileOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueProfile, setValueProfile] = useState();

  const profileOptions = [];

  // Limpa o optionsFilled para realizar preenchimento dos campos novamente
  history.listen((location) => {
    if (location.pathname !== '/Admin/Permissoes') {
      optionsFilled = false;
    }
  });

  const getPermissions = async (value) => {
    setLoading(true);
    try{
      await AppointmentApi.get(`Permissions/PermissionsByProfile?profile=${value}`)
      .then((res) => {
        if (res.data !== null && res.data !== undefined && res.data !== '') {
          let actualData = [];
          actualData = Object.keys(res.data).map((key) => {
            const dataValue = res.data[key];
            return {
              resource: dataValue.st_resource,
              view: dataValue.fl_view,
              insert: dataValue.fl_include,
              edit: dataValue.fl_edit,
              delete: dataValue.fl_delete,
              cd_permission: dataValue.cd_permission,
              key: dataValue.cd_permission,
            };
          });
          setData(actualData);
          return actualData;
        }
        return res;
      })
    } catch (err) {
          if (err.response === undefined) {
            toast.error('Servidor indisponível!');
            return [];
          }
          if (err.response.status === 400) {
            return [];
          }
          return [];
    }finally {
      setLoading(false);
    }
  };

  const fulfillProfileSelect = async () => {
    setLoading(true);
    try {
        const response = await AppointmentApi.get('Profiles');
        const profiles = response.data;

        if (Array.isArray(profiles)) {
            const options = profiles.map((profile) => ({
                label: profile.st_name,
                value: profile.cd_profile
            }));

            setStateProfileOptions(options);
            setValueProfile(options[0]?.value); // Definindo o valor inicial, se houver opções
        } else {
            // Caso não seja um array, tratamento de erro
            console.error('O retorno não é um array:', profiles);
            toast.error('Erro ao obter perfis: formato de dados inválido');
        }
    } catch (err) {
        if (!err.response) {
            // Tratamento de erro para erro de rede ou servidor offline
            console.error('Erro de rede ou servidor offline:', err);
            toast.error('Servidor indisponível!');
        } else if (err.response.status === 400) {
            // Tratamento de erro para status 400 (Bad Request)
            console.error('Erro 400:', err.response.data);
            toast.error('Erro na requisição: ' + err.response.data.message);
        } else {
            // Outros erros de resposta do servidor
            console.error('Erro desconhecido:', err);
            toast.error('Erro desconhecido ao obter perfis');
        }
    } finally {
        setLoading(false); // Sempre garantir que setLoading(false) seja chamado
    }
};


  async function setUseEffect(){
    if (!optionsFilled) {
      await getPermissions('1');
      fulfillProfileSelect();
      optionsFilled = true;
    }
  }
   
  useEffect(async () => {
    await setUseEffect();
  }, [optionsFilled]);

  // useEffect(async () => {
  //   if (!optionsFilled) {
  //     await getPermissions('1');
  //     fulfillProfileSelect();
  //     optionsFilled = true;
  //   }
  // }, [optionsFilled]);

  const handleCheckbox = (text, record, key) => {
    let getKey = ['Fl_view', 'Fl_include', 'Fl_edit', 'Fl_delete'];
    getKey = getKey.filter((value) => value !== key);
    const dataPut = {
      Cd_permission: record.cd_permission,
      [key]: text === 0 ? 1 : 0,
      [getKey[0]]:
        getKey[0].split('_')[1] === 'include' ? record.insert : record[getKey[0].split('_')[1]],
      [getKey[1]]:
        getKey[1].split('_')[1] === 'include' ? record.insert : record[getKey[1].split('_')[1]],
      [getKey[2]]:
        getKey[2].split('_')[1] === 'include' ? record.insert : record[getKey[2].split('_')[1]],
      Cd_user: userData.id,
    };
    if (!permissions.edit) {
      toast.error('Você não tem permissão para fazer essa alteração!');
    } else {
      AppointmentApi.put('Permissions', dataPut);
    }
  };

  const columns2 = (getColumnSearchProps) => {
    const columns = [
      {
        title: 'Recurso',
        dataIndex: 'resource',
        key: 'resource',
        ...getColumnSearchProps('resource', 'Recurso'),
        sorter: (a, b) => a.resource.length - b.resource.length,
        sortDirections: ['descend', 'ascend'],
        width: '300px',
      },
      {
        dataIndex: 'view',
        title: 'Visualizar',
        key: 'view',
        render: (text, record) =>
          text === 1 ? (
            <div className="antd-table-center-div">
              <input
                type="checkbox"
                id="check"
                className="form-check-input"
                onChange={() => handleCheckbox(text, record, 'Fl_view')}
                defaultChecked="checked"
              />
            </div>
          ) : (
            <div className="antd-table-center-div">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={() => handleCheckbox(text, record, 'Fl_view')}
              />
            </div>
          ),
      },
      {
        dataIndex: 'insert',
        title: 'Cadastrar',
        key: 'insert',
        render: (text, record) =>
          text === 1 ? (
            <div className="antd-table-center-div">
              <input
                type="checkbox"
                id="check"
                className="form-check-input"
                onChange={() => handleCheckbox(text, record, 'Fl_include')}
                defaultChecked="checked"
              />
            </div>
          ) : (
            <div className="antd-table-center-div">
              <input
                className="form-check-input"
                onChange={() => handleCheckbox(text, record, 'Fl_include')}
                type="checkbox"
              />
            </div>
          ),
      },
      {
        dataIndex: 'edit',
        title: 'Editar',
        key: 'edit',
        render: (text, record) =>
          text === 1 ? (
            <div className="antd-table-center-div">
              <input
                onChange={() => handleCheckbox(text, record, 'Fl_edit')}
                type="checkbox"
                id="check"
                className="form-check-input"
                defaultChecked="checked"
              />
            </div>
          ) : (
            <div className="antd-table-center-div">
              <input
                className="form-check-input"
                onChange={() => handleCheckbox(text, record, 'Fl_edit')}
                type="checkbox"
              />
            </div>
          ),
      },
      {
        dataIndex: 'delete',
        title: 'Excluir',
        key: 'delete',
        render: (text, record) =>
          text === 1 ? (
            <div className="antd-table-center-div">
              <input
                onChange={() => handleCheckbox(text, record, 'Fl_delete')}
                type="checkbox"
                className="form-check-input"
                defaultChecked="checked"
              />
            </div>
          ) : (
            <div className="antd-table-center-div">
              <input
                className="form-check-input"
                onChange={() => handleCheckbox(text, record, 'Fl_delete')}
                type="checkbox"
              />
            </div>
          ),
      },
    ];
    return columns;
  };

  return (
    <>
    <ToastContainer />
      <div className="page-content permissions-page">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <Breadcrumb>
                <Breadcrumb.Item>Permissões</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="card-body">
              <div className="mb-4 d-flex align-items-center table-addons">
                <SelectAnd
                  style={{ width: '200px', marginLeft: -5 }}
                  options={stateProfileOptions}
                  className="select-container me-2 company"
                  value={valueProfile}
                  onChange={async (e) => {
                    if (e !== undefined) {
                      setValueProfile(e);
                      await getPermissions(e);
                    }
                  }}
                  loading={loading}
                  dropdownStyle={{ backgroundColor: 'white', borderRadius: '16px' }}
                />
              </div>
              <TableAntd
                columnsTable={(getColumnSearchProps) => columns2(getColumnSearchProps)}
                dataTable={data}
                setData={setData}
                isNotAction
                cdPage="cd_permission"
                nameOfResquest="Permissions"
                routePermissions="permissions"
                getData={() => getPermissions()}
                loadingTable={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
